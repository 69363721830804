/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedDTO_RefResultDTO_ } from '../models/PaginatedDTO_RefResultDTO_'
import type { RefTable } from '../models/RefTable'
import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'
export class ExplorerAnalyticsRefService {
    /**
     * Index
     * @returns PaginatedDTO_RefResultDTO_ Successful Response
     * @throws ApiError
     */
    public static indexExplorerAnalyticsRefRefTableNameGet({
        refTableName,
        page = 1,
        limit = 100,
        query,
        filters
    }: {
        refTableName: RefTable
        /**
         * Page number for pagination, starting at 1
         */
        page?: number
        /**
         * Number of items per page, maximum 1000
         */
        limit?: number
        query?: string | null
        filters?: Array<string>
    }): CancelablePromise<PaginatedDTO_RefResultDTO_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/explorer/analytics/ref/{ref_table_name}',
            path: {
                ref_table_name: refTableName
            },
            query: {
                page: page,
                limit: limit,
                query: query,
                filters: filters
            },
            errors: {
                422: `Validation Error`
            }
        })
    }
}
