/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { ChatModel } from '@om1/falcon-api/codegen/client/models/ChatModel'
import { useIngestConversation } from '@om1/falcon-api/codegen/hooks/useIngestConversation'
import { Dispatch, SetStateAction, useMemo } from 'react'
import { withRouter } from 'react-router-dom'
import { NodeTypes } from '../components/query/QueryBlock'
import { IngestConversation } from './IngestConversation'
import { IngestHeader } from './IngestHeader'

export type SelectedBlockProps = {
    selectedBlock?: NodeTypes | undefined
    setSelectedBlock?: Dispatch<SetStateAction<NodeTypes | undefined>>
}

export function IngestView({ match }) {
    const { conversationId } = match.params
    // Memoize the parameters to ensure stable query keys
    const conversationParams = useMemo(() => ({ id: conversationId, model: 'gpt-4o' as ChatModel }), [conversationId])

    const { isLoading } = useIngestConversation(conversationParams)

    if (isLoading) {
        return (
            <div style={{ minHeight: '100vh', backgroundColor: '#F5F5F5', padding: '2rem', display: 'flex' }}>
                <div style={{ flex: 2, margin: '0 auto' }}>
                    <div style={{ animation: 'pulse 1.5s infinite', gap: '1rem', display: 'flex', flexDirection: 'column' }}>
                        <div style={{ height: '2rem', backgroundColor: '#e2e8f0', borderRadius: '0.5rem', width: '100%' }}></div>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '0.75rem', height: '100%' }}>
                            <div
                                style={{
                                    height: '6rem',
                                    backgroundColor: '#e2e8f0',
                                    borderRadius: '0.5rem',
                                    maxWidth: '800px',
                                    margin: '0 auto',
                                    width: '100%'
                                }}
                            ></div>
                            <div
                                style={{
                                    height: '6rem',
                                    backgroundColor: '#e2e8f0',
                                    borderRadius: '0.5rem',
                                    maxWidth: '800px',
                                    margin: '0 auto',
                                    width: '100%'
                                }}
                            ></div>
                            <div
                                style={{
                                    height: '6rem',
                                    backgroundColor: '#e2e8f0',
                                    borderRadius: '0.5rem',
                                    maxWidth: '800px',
                                    margin: '0 auto',
                                    width: '100%'
                                }}
                            ></div>
                            <div
                                style={{
                                    height: '6rem',
                                    backgroundColor: '#e2e8f0',
                                    borderRadius: '0.5rem',
                                    maxWidth: '800px',
                                    margin: '0 auto',
                                    width: '100%'
                                }}
                            ></div>
                            <div
                                style={{
                                    height: '6rem',
                                    backgroundColor: '#e2e8f0',
                                    borderRadius: '0.5rem',
                                    maxWidth: '800px',
                                    margin: '0 auto',
                                    width: '100%'
                                }}
                            ></div>
                            <div
                                style={{
                                    height: '6rem',
                                    backgroundColor: '#e2e8f0',
                                    borderRadius: '0.5rem',
                                    maxWidth: '800px',
                                    margin: '0 auto',
                                    width: '100%'
                                }}
                            ></div>
                            <div
                                style={{ height: '4rem', backgroundColor: '#e2e8f0', borderRadius: '0.5rem', width: '100%', marginTop: '1rem' }}
                            ></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div style={{ backgroundColor: '#F5F5F5', display: 'flex', flexDirection: 'column' }}>
            <div style={{ position: 'fixed', top: 55, left: 65, right: 0, zIndex: 1000, backgroundColor: '#F5F5F5' }}>
                <IngestHeader conversationId={conversationId} />
            </div>
            <div style={{ marginTop: '64px' }}>
                {' '}
                {/* Adjust this value based on your header height */}
                <IngestConversation conversationId={conversationId} />
            </div>
        </div>
    )
}

export default withRouter(IngestView)
