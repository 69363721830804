import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { ExplorerAssistantConversationService } from '../client'

export const QUERY_KEYS = {
    assistantConversationDetail: 'assistantConversationDetail'
} as const

export type AssistantConversationTokenUsage = {
    inputTokens: number
    outputTokens: number
    totalTokens: number
}

export function useConversation(params: Parameters<typeof ExplorerAssistantConversationService.getExplorerAssistantConversationIdGet>[0]) {
    const memoizedParams = useMemo(() => params, [params])

    const {
        data: assistantConversationDetail,
        isLoading,
        refetch
    } = useQuery({
        queryKey: [QUERY_KEYS.assistantConversationDetail, memoizedParams.id, memoizedParams.model],
        queryFn: () =>
            ExplorerAssistantConversationService.getExplorerAssistantConversationIdGet({
                id: memoizedParams.id,
                model: memoizedParams.model
            })
    })

    const assistantConversationTokenUsage: AssistantConversationTokenUsage = useMemo(() => {
        return (assistantConversationDetail?.messages || []).reduce(
            (acc, message) => {
                if ('usage_metadata' in message && message.usage_metadata) {
                    return {
                        inputTokens: acc.inputTokens + (message.usage_metadata.input_tokens || 0),
                        outputTokens: acc.outputTokens + (message.usage_metadata.output_tokens || 0),
                        totalTokens: acc.totalTokens + (message.usage_metadata.total_tokens || 0)
                    }
                }
                return acc
            },
            { inputTokens: 0, outputTokens: 0, totalTokens: 0 }
        )
    }, [assistantConversationDetail])

    return {
        isLoading,
        assistantConversationDetail,
        refetchAssistantConversationDetail: refetch,
        assistantConversationTokenUsage
    }
}
