import { Trans } from '@lingui/macro'
import { useTheme } from '@mui/material'
import { AssistantAIMessage_Output } from '@om1/falcon-api'
import React from 'react'

interface MessageTokenUsageProps {
    message: AssistantAIMessage_Output
}

export const MessageTokenUsage: React.FC<MessageTokenUsageProps> = ({ message }) => {
    const theme = useTheme()

    return (
        <div
            style={{
                padding: '4px 8px',
                borderRadius: '4px',
                fontSize: '0.75rem',
                color: theme.palette.text.secondary,
                display: 'flex',
                gap: '8px',
                flexGrow: 0
            }}
        >
            <div style={{ display: 'flex', gap: '4px' }}>
                <span style={{ fontWeight: 'bold' }}>
                    <Trans>Tokens</Trans>
                </span>
                <span>
                    {message.usage_metadata?.input_tokens?.toLocaleString()} <Trans>In</Trans>,
                </span>
                <span>
                    {message.usage_metadata?.output_tokens?.toLocaleString()} <Trans>Out</Trans>,
                </span>
                <span>
                    {message.usage_metadata?.total_tokens?.toLocaleString()} <Trans>Total</Trans>
                </span>
            </div>
        </div>
    )
}
