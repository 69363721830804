/* eslint-disable string-to-lingui/missing-lingui-transformation */
import Box from '@mui/material/Box/Box'
import { DateAwareFilterDTO_Output } from '@om1/falcon-api/codegen/client/models/DateAwareFilterDTO_Output'
import { FilterDTO_Output } from '@om1/falcon-api/codegen/client/models/FilterDTO_Output'
import { PaginatedDTO_RefResultDTO_ } from '@om1/falcon-api/codegen/client/models/PaginatedDTO_RefResultDTO_'
import { PaginateMeta } from '@om1/falcon-api/codegen/client/models/PaginateMeta'
import { ExplorerAnalyticsRefService } from '@om1/falcon-api/codegen/client/services/ExplorerAnalyticsRefService'
import { FC, useCallback, useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { fetchData } from '../queryUtils'

export const PatientExternalCohortContents: FC<{ editBlock: FilterDTO_Output | DateAwareFilterDTO_Output }> = ({ editBlock }) => {
    const [refData, setRefData] = useState<Array<Record<string, any>>>([])
    const refTable = editBlock.table === 'patient_external_cohort' ? 'ref_product_custom_cohort' : 'ref_phenotype'
    const fetchRefs = useCallback(
        async (filter: FilterDTO_Output | DateAwareFilterDTO_Output) => {
            const paginatedDict: PaginatedDTO_RefResultDTO_ = await fetchData(
                'explorerCohorts',
                { refTableName: refTable, page: 1, limit: 100, query: null, filters: filter.values },
                ExplorerAnalyticsRefService.indexExplorerAnalyticsRefRefTableNameGet
            )
            setRefData(paginatedDict.data)
        },
        [refTable]
    )
    const [refResults, setRefResults] = useState<Array<Record<string, any>>>([])

    useEffect(() => {
        fetchRefs(editBlock)
    }, [editBlock, fetchRefs])

    const [selectedCodingSystem, setSelectedCodingSystem] = useState<'Custom' | 'PhenOM'>('Custom')
    const [query, setQuery] = useState('')
    const [refMeta, setRefMeta] = useState<PaginateMeta>({
        currentPage: 1,
        totalPages: 0,
        totalItems: 0,
        itemsPerPage: 10
    })
    const [pendingBlockValues, setPendingBlockValues] = useState<Array<string>>(editBlock.values)
    const initialBlockValues = editBlock.values
    const isInitialBlockValues = JSON.stringify(pendingBlockValues) === JSON.stringify(initialBlockValues)

    useEffect(() => {
        if (editBlock) {
            if (editBlock.field === 'phenotype') {
                setSelectedCodingSystem('PhenOM')
            } else {
                setSelectedCodingSystem('Custom')
            }
        }
    }, [editBlock])

    useEffect(() => {
        const fetchDataAsync = async () => {
            const data = await fetchData(
                'explorerCohorts',
                {
                    refTableName: selectedCodingSystem === 'Custom' ? 'ref_product_custom_cohort' : 'ref_phenotype',
                    page: 1,
                    limit: 100,
                    query: query,
                    filters: []
                },
                ExplorerAnalyticsRefService.indexExplorerAnalyticsRefRefTableNameGet
            )
            setRefData((prevData) => [...prevData, ...data.data])
            setRefResults(data.data)
            setRefMeta(data.meta)
        }

        fetchDataAsync()
    }, [query, selectedCodingSystem])

    const fetchMoreData = useCallback(async () => {
        const data = await fetchData(
            'explorerCohorts',
            {
                refTableName: selectedCodingSystem === 'Custom' ? 'ref_product_custom_cohort' : 'ref_phenotype',
                page: refMeta?.currentPage + 1,
                limit: 100,
                query: query,
                filters: []
            },
            ExplorerAnalyticsRefService.indexExplorerAnalyticsRefRefTableNameGet
        )

        setRefData((prevData) => [...prevData, ...data.data])
        setRefResults(data.data)
        setRefMeta(data.meta)
    }, [refMeta?.currentPage, query, selectedCodingSystem])

    const hasMoreData = refMeta?.currentPage && refMeta?.totalPages ? refMeta?.currentPage < refMeta?.totalPages : false

    function handleSelect(diagnosis_concept_id: string): void {
        setPendingBlockValues([diagnosis_concept_id].concat(pendingBlockValues))
    }

    function handleRemove(value: string): void {
        setPendingBlockValues(pendingBlockValues.filter((v) => v !== value))
    }

    return (
        <div style={{ padding: '20px', position: 'relative' }}>
            <input
                type='text'
                placeholder={selectedCodingSystem === 'Custom' ? 'Search OM1 Coding System' : 'Search PhenOM Characteristics'}
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                style={{ width: '100%', padding: '10px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: '5px' }}
            />
            <div style={{ display: 'flex', marginBottom: '20px', width: '100dvw' }}>
                <label style={{ marginRight: '20px' }}>
                    <input
                        type='radio'
                        name='codingSystem'
                        value='Custom'
                        checked={selectedCodingSystem === 'Custom'}
                        onChange={() => {
                            setSelectedCodingSystem('Custom')
                            setQuery('')
                            setRefData([])
                            setRefResults([])
                        }}
                        style={{ marginRight: '10px' }}
                        disabled={!!(query || (!query && pendingBlockValues.length > 0)) && selectedCodingSystem !== 'Custom'}
                    />
                    Search Product Custom Cohort
                </label>
                <label style={{ marginRight: '20px' }}>
                    <input
                        type='radio'
                        name='codingSystem'
                        value='PhenOM'
                        checked={selectedCodingSystem === 'PhenOM'}
                        onChange={() => {
                            setSelectedCodingSystem('PhenOM')
                            setQuery('')
                            setRefData([])
                            setRefResults([])
                        }}
                        style={{ marginRight: '10px' }}
                        disabled={!!(query || (!query && pendingBlockValues.length > 0)) && selectedCodingSystem !== 'PhenOM'}
                    />
                    Search PhenOM Characteristics
                </label>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '60px' }}>
                <div
                    style={{
                        flex: 1,
                        marginRight: '10px',
                        border: '1px solid #ccc',
                        padding: '10px',
                        borderRadius: '5px',
                        minHeight: '350px',
                        overflow: 'hidden'
                    }}
                >
                    <strong>Search Results</strong>
                    <div
                        style={{
                            flex: 1,
                            minHeight: '350px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        {refResults.length === 0 && <div style={{ marginTop: '10px', color: '#aaa' }}>Search to browse OM1 codes</div>}
                        <Box width='100%' height='100%'>
                            <InfiniteScroll
                                dataLength={refResults.length}
                                next={() => {
                                    fetchMoreData()
                                }}
                                hasMore={hasMoreData}
                                loader={
                                    <div className='flex justify-center items-center py-4'>
                                        <div className='animate-spin rounded-full h-8 w-8 border-b-2 border-primary'></div>
                                    </div>
                                }
                                className='w-full overflow-y-scroll pr-3 mr-[-12px] space-y-3 max-h-[350px]'
                            >
                                {refResults.map((item, index) => (
                                    <div
                                        key={`${item.cohort}-${index}`}
                                        onClick={() => {
                                            if (!pendingBlockValues.includes(item.cohort) && !pendingBlockValues.includes(item.phenotype)) {
                                                handleSelect(selectedCodingSystem === 'Custom' ? item.cohort : item.phenotype)
                                            }
                                        }}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginTop: '10px',
                                            width: '100%',
                                            borderBottom: '1px solid #ccc',
                                            padding: '10px',
                                            height: '100%'
                                        }}
                                    >
                                        <input
                                            type='checkbox'
                                            style={{ marginRight: '10px' }}
                                            onClick={() => {
                                                if (!pendingBlockValues.includes(item.cohort) && !pendingBlockValues.includes(item.phenotype)) {
                                                    handleSelect(selectedCodingSystem === 'Custom' ? item.cohort : item.phenotype)
                                                }
                                            }}
                                            disabled={pendingBlockValues.includes(item.cohort) || pendingBlockValues.includes(item.phenotype)}
                                            checked={pendingBlockValues.includes(item.cohort) || pendingBlockValues.includes(item.phenotype)}
                                            onChange={() => {
                                                if (!pendingBlockValues.includes(item.cohort) && !pendingBlockValues.includes(item.phenotype)) {
                                                    handleSelect(selectedCodingSystem === 'Custom' ? item.cohort : item.phenotype)
                                                }
                                            }}
                                        />
                                        <span
                                            style={{
                                                textAlign: 'left',
                                                textTransform: 'none',
                                                flex: 1,
                                                paddingBottom: '10px'
                                            }}
                                        >
                                            {selectedCodingSystem === 'Custom' ? item.cohort : item.phenotype}
                                        </span>
                                    </div>
                                ))}
                            </InfiniteScroll>
                        </Box>
                    </div>
                </div>
                <div style={{ flex: 1, marginLeft: '10px', border: '1px solid #ccc', padding: '10px', borderRadius: '5px', minHeight: '150px' }}>
                    <strong>
                        Selected{' '}
                        <button
                            className='text-xs'
                            style={{
                                color: '#007bff',
                                textDecoration: 'none',
                                cursor: 'pointer',
                                marginLeft: '5px',
                                background: 'none',
                                border: 'none',
                                padding: '0'
                            }}
                            onClick={() => setPendingBlockValues([])}
                        >
                            Remove all
                        </button>
                    </strong>
                    <div
                        style={{
                            marginTop: '10px',
                            paddingLeft: '10px',
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                            maxHeight: '350px',
                            overflow: 'scroll'
                        }}
                    >
                        {pendingBlockValues.map((value, index) => (
                            <div
                                key={`${value}-${index}`}
                                style={{
                                    padding: '10px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '100%',
                                    height: '100%',
                                    borderBottom: '1px solid #ccc',
                                    marginTop: index === 0 ? '0px' : '10px'
                                }}
                            >
                                <span style={{ marginRight: '10px', cursor: 'pointer' }} onClick={() => handleRemove(value)}>
                                    🗑️
                                </span>
                                <span>
                                    {selectedCodingSystem === 'Custom'
                                        ? refData.find((item) => item.cohort === value)?.cohort ||
                                          refResults.find((item) => item.cohort === value)?.cohort
                                        : refData.find((item) => item.phenotype === value)?.phenotype ||
                                          refResults.find((item) => item.phenotype === value)?.phenotype}
                                </span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <button
                style={{
                    position: 'absolute',
                    bottom: '20px',
                    right: '20px',
                    backgroundColor: isInitialBlockValues ? '#ccc' : '#004085',
                    color: '#fff',
                    padding: '10px 20px',
                    border: 'none',
                    borderRadius: '5px'
                }}
                disabled={isInitialBlockValues}
            >
                + Save Criteria
            </button>
        </div>
    )
}
