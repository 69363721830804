import { PaginateMeta } from '@om1/falcon-api'
import { CohortListItem } from '../cohort-state'

export type Cohorts = {
    [key in CohortOwner]: CohortListItem[]
}

export interface CohortListState {
    loading: boolean
    cohorts: Cohorts
    paginationMeta: PaginateMeta
}

export enum CohortOwner {
    System = 'SYSTEM',
    User = 'USER'
}

export const initialCohortListState: CohortListState = {
    loading: true,
    cohorts: {
        [CohortOwner.System]: [],
        [CohortOwner.User]: []
    },
    paginationMeta: {
        currentPage: 1,
        itemsPerPage: 10,
        totalItems: 0,
        totalPages: 0
    }
}

export type CohortListQueryParams = {
    [key in CohortOwner]: CohortListQueryObject
}

export interface CohortListQueryObject {
    layout?: CohortLayout
    searchValue?: string
    page?: number
    pageSize?: number
    deletedId?: string
}

export enum CohortLayout {
    Grid = 'GRID',
    Card = 'CARD'
}
