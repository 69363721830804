import { withRouter } from '@om1/platform-routing'
import { connectRedux } from '@om1/platform-utils'
import { PlatformSettingsState } from '../state'
import { AnalyticsBuildPageComponent } from './AnalyticsBuildPageComponent'

export function createAnalyticsBuildPage<TState extends { platformSettings: PlatformSettingsState }>() {
    return connectRedux(
        withRouter(AnalyticsBuildPageComponent),
        (state: TState) => {
            return {}
        },
        {}
    )
}

export type AnalyticsBuildPage = ReturnType<typeof createAnalyticsBuildPage>
