/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { t, Trans } from '@lingui/macro'
import { ArrowDropDown as ArrowDropDownIcon } from '@mui/icons-material'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import BarChartIcon from '@mui/icons-material/BarChart'
import CodeIcon from '@mui/icons-material/Code'
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined'
import { alpha, Box, Breadcrumbs, Button, Divider, Grid, IconButton, Menu, MenuItem, Skeleton, SxProps } from '@mui/material'
import { QueryDTO_Input } from '@om1/falcon-api'
import { AssistantConversationCreateDTO } from '@om1/falcon-api/codegen/client/models/AssistantConversationCreateDTO'
import { AssistantConversationDTO } from '@om1/falcon-api/codegen/client/models/AssistantConversationDTO'
import { explorerAssistantConversationActions } from '@om1/platform-assistant-ui/src/state/explorer-assistant-conversation-actions'
import { useUserPermissions } from '@om1/platform-authentication'
import { LightTooltip } from '@om1/platform-components/Tooltip'
import { Routes, toPath } from '@om1/platform-routing'
import { trackingActions, TrackingPlanEvents } from '@om1/platform-tracking'
import { BreadcrumbLink } from '@om1/platform-ui-kit/src/components/Layout'
import { PlatformPermissions, RoutedFrameworkComponentProps } from '@om1/platform-utils'
import { DragPreview, useDrag } from '@react-aria/dnd'
import { isEqualWith } from 'lodash'
import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { CohortDashboardCohortCard } from '../../../pages/CohortDashboardPage/CohortDashboardCohortCard'
import { CohortRoutes } from '../../../routes'
import {
    cohortBlocksEditActions,
    cohortCommonActions,
    CohortDraggingState,
    cohortEditActions,
    CohortListItem,
    CohortTreeActionType,
    CriteriaFiltersDialogOpenState,
    CriteriaFiltersDialogState,
    CriteriaOperation,
    CriteriaRelationDialogState,
    CriteriaRelationType,
    CriteriaType,
    CriterionNode,
    DateRelationMetadata,
    FollowUpLengthEditDialogState,
    FollowUpRelationMetadata,
    isAndOperationNode,
    isInsertAction,
    isUpdateAction,
    LabNumericQualifierEditDialogState,
    ObservationScoreEditDialogState,
    OccurrenceEditDialogState,
    OperationNode,
    PatientAgeEditDialogState,
    QualifierType,
    QueryFilterBase,
    RecencyEditDialogState,
    RefLabelsState,
    SpecialtyEditDialogState
} from '../../../state'
import { findNode } from '../../../state/edit/blocks/cohort-blocks-edit-reducer'
import { createGenerateAttritionFunnelComponent } from '../../buttons/GenerateAttritionFunnel'
import { CohortStickyHeader } from '../../shared/CohortStickyHeader'
import { CohortSaveValues } from '../../shared/CreateEditCohortComponent'
import { CriteriaTypeIcon } from '../../shared/CriteriaTypeIcon'
import { CriteriaTypeLabel } from '../../shared/CriteriaTypeLabel'
import EditCohortDialogComponent from '../../shared/EditCohortDialogComponent'
import { splitAttritionFunnel } from '../../shared/SplitAttritionFunnel'
import { CriteriaArea } from '../blocks/CriteriaArea'
import { DateRelationDialog } from '../blocks/DateRelationDialog'
import { createFollowUpLengthEditDialogComponent } from '../blocks/FollowUpLengthEditDialog'
import { FollowUpRelationDialog } from '../blocks/FollowUpRelationDialog'
import { createLabNumericQualifierEditDialogComponent } from '../blocks/LabNumericQualifierEditDialogComponent'
import { createObservationPeriodDialogComponent } from '../blocks/ObservationPeriodDialog'
import { DataType } from '../blocks/ObservationPeriodDialogComponent'
import { createObservationScoreEditDialogComponent } from '../blocks/ObservationScoreEditDialog'
import { createOccurrenceEditDialogComponent } from '../blocks/OccurrenceEditDialog'
import { createPatientAgeEditDialogComponent } from '../blocks/PatientAgeEditDialog'
import { createPatientAttributesDialog } from '../blocks/PatientAttributesDialog'
import { createRecencyEditDialogComponent } from '../blocks/RecencyEditDialog'
import {
    createRefTreeDialog,
    DIAGNOSIS_REF_FIELD_MAPPER_CONFIG,
    EXTERNAL_COHORT_REF_FIELD_MAPPER_CONFIG,
    LAB_REF_FIELD_MAPPER_CONFIG,
    MEDICATION_REF_FIELD_MAPPER_CONFIG,
    OBSERVATION_REF_FIELD_MAPPER_CONFIG,
    PROCEDURE_REF_FIELD_MAPPER_CONFIG
} from '../blocks/RefTreeDialog'
import { createSpecialtyEditDialogComponent } from '../blocks/SpecialtyEditDialog'
import { blocksToFilters } from '../utils/filter-export'
import { RefFieldMapper } from '../utils/ref-field-mappers'
import { createCohortSqlDialog } from './CohortSqlDialog'
import { PatientCount } from './PatientCount'

// The order in which the new criteria drag buttons appear in the UI
const CRITERIA_ORDER = [
    CriteriaType.PatientAttributes,
    CriteriaType.Diagnosis,
    CriteriaType.Medication,
    CriteriaType.Observation,
    CriteriaType.Procedure,
    CriteriaType.LabTest,
    CriteriaType.EhrNotes,
    CriteriaType.ObservationPeriod,
    CriteriaType.ExternalCohort
]
// Whether or not we support editing the block in the UI
export const CRITERIA_SUPPORT: Record<CriteriaType, boolean> = {
    [CriteriaType.Demographics]: true,
    [CriteriaType.PatientAttributes]: true,
    [CriteriaType.Diagnosis]: true,
    [CriteriaType.Procedure]: true,
    [CriteriaType.LabTest]: true,
    [CriteriaType.Observation]: true,
    [CriteriaType.Medication]: true,
    [CriteriaType.EhrNotes]: true,
    [CriteriaType.ObservationPeriod]: true,
    [CriteriaType.ExternalCohort]: true
}
export const CRITERIA_DEFAULTS: Partial<Record<CriteriaType, QueryFilterBase[]>> = {
    [CriteriaType.EhrNotes]: [
        {
            table: 'patient_note',
            field: 'has_notes',
            operator: 'in',
            // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
            values: ['True']
        }
    ]
}
export const REF_LABEL_FIELD_CONSOLIDATION_MAP = {
    region: 'state',
    sub_region: 'state'
}

const PatientAttributesDialog = createPatientAttributesDialog()
const OccurrenceEditDialog = createOccurrenceEditDialogComponent()
const RecencyEditDialog = createRecencyEditDialogComponent()
const CohortSqlDialog = createCohortSqlDialog()
const PatientAgeEditDialog = createPatientAgeEditDialogComponent()
const ObservationPeriodDialog = createObservationPeriodDialogComponent()
const FollowUpLengthEditDialog = createFollowUpLengthEditDialogComponent()
const SpecialtyEditDialog = createSpecialtyEditDialogComponent()
const GenerateAttritionFunnel = createGenerateAttritionFunnelComponent()
const ObservationScoreEditDialog = createObservationScoreEditDialogComponent()
const LabNumericQualifierEditDialog = createLabNumericQualifierEditDialogComponent()

export interface CohortEditUrlParams {
    cohortId: string
}

export interface RefFieldMapperConfig<T> {
    mapper: RefFieldMapper<T>
    label: ReactNode
    id: string
    searchInfo?: ReactNode
    isInternal?: boolean // true if the config should only be shown to those with @om1.com emails
    permissions?: string[] // permissions required to access the ref field mapper*/
}

export type CohortEditComponentProps = RoutedFrameworkComponentProps<
    Record<string, never>,
    CohortEditUrlParams,
    {
        cohort: CohortListItem
        tree: OperationNode
        lastSavedBlocks: OperationNode
        cohortLoading: boolean
        criteriaDialog: CriteriaFiltersDialogState
        criteriaRelationDialog: CriteriaRelationDialogState
        occurrenceEditDialog: OccurrenceEditDialogState
        recencyEditDialog: RecencyEditDialogState
        patientAgeEditDialog: PatientAgeEditDialogState
        specialtyEditDialog: SpecialtyEditDialogState
        followUpLengthEditDialog: FollowUpLengthEditDialogState
        observationScoreEditDialog: ObservationScoreEditDialogState
        labNumericQualifierEditDialog: LabNumericQualifierEditDialogState
        dragState: CohortDraggingState
        updateLoading: boolean
        sizeLoading: boolean
        refLabels: RefLabelsState
        conversations: AssistantConversationDTO[]
    },
    typeof cohortEditActions &
        typeof cohortBlocksEditActions &
        typeof cohortCommonActions &
        typeof trackingActions &
        typeof explorerAssistantConversationActions,
    Record<never, never>
>

/**
 * A screen for editing the entirety of a cohort.
 */
export const CohortEditComponent: React.FunctionComponent<CohortEditComponentProps> = ({ state, actions, routing }) => {
    const [editModalVisible, setEditModalVisible] = useState(false)
    const [sqlDialogVisible, setSqlDialogVisible] = useState(false)
    const { permissions } = useUserPermissions()
    const canViewSqlButton = useMemo(() => permissions?.includes(PlatformPermissions.GET_COHORTS_SQL), [permissions])
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const history = useHistory()
    useEffect(() => {
        if (permissions?.includes(PlatformPermissions.ACCESS_ASSISTANT) && state.cohort.id) {
            actions.getConversations({ path: {}, query: { referenceCohortId: state.cohort.id, page: 1, limit: 100 } })
        }
    }, [actions, state.cohort.id, permissions])

    const onSave = useCallback(
        (refresh: boolean = true) => {
            const value = blocksToFilters(state.tree)
            actions.cohortUpdateQuery({ id: state.cohort.id, query: value }, refresh)
            actions.trackEvent(TrackingPlanEvents.EDIT_COHORT_SAVE, {
                id: state.cohort.id,
                name: state.cohort.name,
                analyticsDataset: state.cohort.analyticsDataset
            })
        },
        [actions, state.cohort, state.tree]
    )

    const isCriteriaPillEnabled = useCallback((criteriaType: CriteriaType): boolean => {
        return CRITERIA_SUPPORT[criteriaType]
    }, [])
    const isCriteriaPillShown = useCallback(
        (criteriaType: CriteriaType): boolean => {
            if (criteriaType === CriteriaType.ExternalCohort && !permissions.includes(PlatformPermissions.ACCESS_EXTERNAL_COHORTS)) {
                return false
            }
            if (criteriaType === CriteriaType.Procedure && !permissions.includes(PlatformPermissions.ACCESS_PROCEDURES)) {
                return false
            }
            return true
        },
        [permissions]
    )

    useEffect(() => {
        return () => {
            actions.cohortClear()
        }
    }, [actions])

    // Listens for additions of criteria types that we do not yet support. It immediately adds the block instead of opening a modal.
    // Can be removed when all block types have edit support.
    useEffect(() => {
        if (state.criteriaDialog && state.criteriaDialog.type && !isCriteriaPillEnabled(state.criteriaDialog.type)) {
            const uuid = uuidv4()
            actions.criteriaInsert({ target: state.criteriaDialog.action.target, criteriaType: state.criteriaDialog.type, filters: [], uuid })
            actions.criteriaDialogTrigger({})
        }
    }, [state.criteriaDialog, isCriteriaPillEnabled, actions])

    const handleBlockAdd = (nodeId: string) => {
        const uuid = uuidv4()
        actions.operationInsert({ uuid, operation: CriteriaOperation.OR, target: { nodeId } })
    }

    const handleInclusionBlockAdd = () => {
        handleBlockAdd(state.tree.children[0].id)
    }

    const handleExclusionBlockAdd = () => {
        handleBlockAdd(state.tree.children[1].id)
    }

    const handleSqlDialogOpen = () => {
        setSqlDialogVisible(true)
    }

    const handleSqlDialogClose = useCallback(() => {
        setSqlDialogVisible(false)
    }, [])

    const handleDialogCancel = useCallback(() => {
        actions.criteriaDialogTrigger({})
    }, [actions])

    const handleDialogRemoveQualifiers = useCallback(
        (type: QualifierType[] = []) => {
            if (isOpenDialog(state.criteriaDialog)) {
                const { action } = state.criteriaDialog
                if (isUpdateAction(action)) {
                    if (type.length) {
                        const filters = findNode(state.tree, action.target.nodeId) as CriterionNode
                        const qualifiers = filters?.qualifiers.filter((qualifier) => !type.includes(qualifier.type))
                        actions.criteriaUpdate({ target: action.target, qualifiers })
                    } else {
                        actions.criteriaUpdate({ target: action.target, qualifiers: [] })
                    }
                }
            }
        },
        [actions, state.criteriaDialog, state.tree]
    )

    const handleDialogSave = useCallback(
        (filters: QueryFilterBase[]) => {
            if (isOpenDialog(state.criteriaDialog)) {
                const { type, action } = state.criteriaDialog
                if (isInsertAction(action)) {
                    const uuid = uuidv4()
                    actions.criteriaInsert({ target: action.target, criteriaType: type, filters, uuid })
                    if (action.target.relate) {
                        // Show the relation dialog if the action was a relate action
                        actions.criteriaRelationDialogTrigger({
                            type: action.target.relateType ? action.target.relateType : CriteriaRelationType.Date,
                            target: { nodeId: action.target.nodeId as string, relateType: action.target.relateType }
                        })
                    }
                } else if (isUpdateAction(action)) {
                    actions.criteriaUpdate({ target: action.target, filters })
                }
            }
            actions.criteriaDialogTrigger({})
        },
        [actions, state.criteriaDialog]
    )

    const handleRelationDialogCancel = useCallback(() => {
        actions.criteriaRelationDialogTrigger({})
    }, [actions])

    const handleDateRelationDialogSave = useCallback(
        (relations: DateRelationMetadata) => {
            actions.criteriaRelationUpdate(
                {
                    relationData: { dateRelation: relations },
                    target: state.criteriaRelationDialog.target
                },
                true
            )
            actions.criteriaRelationDialogTrigger({})
        },
        [actions, state.criteriaRelationDialog]
    )

    const handleFollowUpRelationDialogSave = useCallback(
        (relations: FollowUpRelationMetadata) => {
            actions.criteriaRelationUpdate(
                {
                    relationData: { followUpRelation: relations },
                    target: state.criteriaRelationDialog.target
                },
                true
            )
            actions.criteriaRelationDialogTrigger({})
        },
        [actions, state.criteriaRelationDialog]
    )

    const handleDataTypeDialogCancel = useCallback(() => {
        actions.dataTypesDialogTrigger({})
        handleDialogCancel()
    }, [actions, handleDialogCancel])

    const handleObservationPeriodDialogSave = useCallback(
        (dataType: DataType) => {
            const { action } = state.criteriaDialog
            actions.observationPeriodUpdate({
                state: {
                    value: dataType
                },
                target: action.target,
                actionType: action.type
            })
            actions.criteriaRelationDialogTrigger({})
            handleDialogCancel()
        },
        [actions, handleDialogCancel, state.criteriaDialog]
    )

    const handleUpdateCohort = useCallback(
        (cohort: CohortSaveValues) => {
            actions.cohortUpdate(cohort, true)
        },
        [actions]
    )

    const handleRefreshCount = useCallback(() => {
        if (state.cohort.isStale) {
            actions.cohortAttritionFunnelSet({ cohortId: state.cohort.id, attritionFunnel: undefined })
        }
        actions.cohortSizeGet({ cohortId: state.cohort.id, cohortName: state.cohort.name })
    }, [actions, state.cohort])

    const canPreview = state.cohort.cohortSize !== null

    const renderRefreshCountButton = useCallback(() => {
        return (
            <Box id={`cohort-refresh-count-${state.cohort.id}`} key={`cohort-refresh-count-${state.cohort.id}`}>
                <LightTooltip title={t`Refresh Count`} placement='top'>
                    <span>
                        <IconButton
                            onClick={handleRefreshCount}
                            disabled={state.sizeLoading}
                            aria-label={t`Refresh Cohort Size`}
                            sx={{
                                backgroundColor: '#D6E4F2',
                                color: '#012D72',
                                margin: '5px',
                                border: '.25px solid #012D72', // Border around the combined button
                                borderRadius: '4px'
                            }}
                        >
                            <RefreshOutlinedIcon />
                        </IconButton>
                    </span>
                </LightTooltip>
            </Box>
        )
    }, [handleRefreshCount, state.cohort.id, state.sizeLoading])

    const renderViewSqlButton = useCallback(() => {
        // TODO: replace this email check with a more robust role/permissions check
        if (canViewSqlButton) {
            return (
                <Box key={`cohort-view-sql-dialog-${state.cohort.id}`}>
                    <LightTooltip title={t`View SQL`} placement='top'>
                        <span>
                            <IconButton
                                onClick={handleSqlDialogOpen}
                                disabled={!canPreview}
                                aria-label={t`View SQL`}
                                sx={{
                                    backgroundColor: '#D6E4F2',
                                    color: '#012D72',
                                    margin: '5px',
                                    border: '.25px solid #012D72', // Border around the combined button
                                    borderRadius: '4px'
                                }}
                            >
                                <CodeIcon />
                            </IconButton>
                        </span>
                    </LightTooltip>
                </Box>
            )
        } else {
            return null
        }
    }, [state.cohort.id, canPreview, canViewSqlButton])

    const renderReportsButton = useCallback(() => {
        return (
            <Box id={`cohort-preview-dialog-${state.cohort.id}`} key={`cohort-reports-dialog-${state.cohort.id}`}>
                <LightTooltip key={'reportTooltip'} title={<Trans>Reports</Trans>} placement='top'>
                    <IconButton
                        key='distribution'
                        color='primary'
                        component={Link}
                        to={toPath(Routes.COHORTBUILD) + `/${CohortRoutes.REPORTS}/${state.cohort.id}/${CohortRoutes.DEMOGRAPHICS}`}
                        aria-label={t`Reports`}
                        sx={{
                            backgroundColor: '#D6E4F2',
                            color: '#012D72',
                            margin: '5px',
                            border: '.25px solid #012D72', // Border around the combined button
                            borderRadius: '4px'
                        }}
                    >
                        <BarChartIcon />
                    </IconButton>
                </LightTooltip>
            </Box>
        )
    }, [state.cohort.id])

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
    }

    const handleCreateConversation = useCallback(() => {
        const newConversation: AssistantConversationCreateDTO = {
            referenceCohortId: state.cohort.id
        }
        actions.launchNewConversation(newConversation, state.cohort.query as QueryDTO_Input)
    }, [actions, state.cohort.id, state.cohort.query])

    const handleAccessExistingConversation = useCallback(
        (conversationId: string) => {
            history.push(toPath(Routes.COHORTBUILD) + `/${CohortRoutes.ASSISTANT}/conversation/${conversationId}`)
        },
        [history]
    )

    const renderChatButton = useCallback(() => {
        if (permissions.includes(PlatformPermissions.ACCESS_ASSISTANT)) {
            return (
                <Box id={`cohort-chat-dialog-${state.cohort.id}`} key={`cohort-chat-dialog-${state.cohort.id}`} display='flex' alignItems='center'>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            border: '.25px solid #012D72', // Border around the combined button
                            borderRadius: '4px', // Rounded corners for the entire button
                            overflow: 'hidden', // Ensures the border radius is applied correctly
                            margin: '5px' // Margin around the combined button
                        }}
                    >
                        <LightTooltip key={'chatTooltip'} title={<Trans>Launch Assistant from this Cohort</Trans>} placement='top'>
                            <IconButton
                                key='chat'
                                color='primary'
                                onClick={handleCreateConversation}
                                aria-label={t`Start Conversation from this Cohort`}
                                sx={{
                                    background: 'linear-gradient(45deg, #012D72 30%, #8AB8E8 90%)',
                                    color: '#012D72',
                                    borderRadius: '0', // No border radius on individual buttons
                                    margin: '0' // Remove margin
                                }}
                            >
                                <AutoAwesomeIcon sx={{ margin: '0.1rem', color: 'white' }} />
                            </IconButton>
                        </LightTooltip>
                        {permissions.includes(PlatformPermissions.ACCESS_ADVANCED_ASSISTANT) && (
                            <LightTooltip key={'chatDropdownTooltip'} title={<Trans>Open Conversation</Trans>} placement='top'>
                                <IconButton
                                    color='primary'
                                    onClick={handleMenuOpen}
                                    aria-label={t`Access Existing Conversations`}
                                    sx={{
                                        backgroundColor: '#D6E4F2',
                                        color: '#012D72',
                                        borderRadius: '0', // No border radius on individual buttons
                                        margin: '0', // Remove margin
                                        borderLeft: '1px solid #012D72' // Border between halves
                                    }}
                                    disabled={state.conversations?.length === 0}
                                >
                                    <ArrowDropDownIcon />
                                </IconButton>
                            </LightTooltip>
                        )}
                    </Box>
                    {permissions.includes(PlatformPermissions.ACCESS_ADVANCED_ASSISTANT) && (
                        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                            {state.conversations?.length > 0 &&
                                state.conversations.map((conversation, index) => (
                                    <MenuItem key={`conversation-${index}`} onClick={() => handleAccessExistingConversation(conversation.id)}>
                                        {conversation.title || conversation.id}
                                    </MenuItem>
                                ))}
                        </Menu>
                    )}
                </Box>
            )
        } else {
            return null
        }
    }, [permissions, state.cohort.id, state.conversations, handleCreateConversation, anchorEl, handleAccessExistingConversation])

    const renderAttritionFunnelButton = useCallback(() => {
        if (permissions?.includes(PlatformPermissions.ACCESS_ATTRITION_FUNNEL)) {
            return <GenerateAttritionFunnel cohort={state.cohort} />
        } else {
            return <></>
        }
    }, [permissions, state.cohort])

    useEffect(() => {
        // Closes the edit modal when a cohort is updated
        setEditModalVisible(false)
    }, [state.cohort])

    useEffect(() => {
        if (state.tree.children.length > 0) {
            if (
                !isEqualWith(state.lastSavedBlocks, state.tree, (initialValue, currentValue, key) => {
                    // ignore randomly generated ids when comparing
                    // allows deleting and rebuilding criteria to be considered "equal"
                    return key === 'id' ? true : undefined
                })
            ) {
                const nameChangeOnly = isEqualWith(state.lastSavedBlocks, state.tree, (initialValue, currentValue, key) => {
                    return key === 'name' ? true : undefined
                })
                onSave(!nameChangeOnly)
            }
        }
    }, [state.tree, state.lastSavedBlocks, onSave])

    const useExclusionRoot = (blocks: OperationNode): { exclusionRoot?: OperationNode } => {
        return useMemo(() => {
            let exclusionRoot: OperationNode | undefined
            if (
                blocks &&
                blocks.children &&
                blocks.children.length > 1 &&
                isAndOperationNode(blocks.children[1]) &&
                blocks.children[1].children.length > 0
            ) {
                exclusionRoot = blocks.children[1]
            }
            return { exclusionRoot }
        }, [blocks])
    }

    function useInclusionRoot(blocks: OperationNode | undefined): { inclusionRoot?: OperationNode } {
        let inclusionRoot: OperationNode | undefined

        if (
            !blocks ||
            !blocks.children ||
            blocks.children.length === 0 ||
            !isAndOperationNode(blocks.children[0]) ||
            blocks.children[0].children.length === 0
        ) {
            return { inclusionRoot: undefined }
        }

        inclusionRoot = blocks.children[0]
        return { inclusionRoot }
    }

    // Usage
    const blocks = useMemo(() => state.tree, [state.tree])
    const { exclusionRoot } = useExclusionRoot(blocks)
    const { inclusionRoot } = useInclusionRoot(blocks)

    const { attritionInclusionLabels, attritionExclusionLabels } = splitAttritionFunnel(
        state.cohort.attritionFunnel || [],
        state.cohort.analyticsDataset.size,
        inclusionRoot,
        exclusionRoot
    )

    // Memoize criteriaDialog to prevent recreation when size loading changes
    const criteriaDialog = useMemo(() => {
        if (state.criteriaDialog.type === undefined || !CRITERIA_SUPPORT[state.criteriaDialog.type]) {
            return null
        } else if (state.criteriaDialog.type === CriteriaType.PatientAttributes) {
            return (
                <PatientAttributesDialog
                    action={state.criteriaDialog.action.type}
                    initialValue={state.criteriaDialog.action.type === CohortTreeActionType.Update ? state.criteriaDialog.action.filters : []}
                    onCancel={handleDialogCancel}
                    onSave={handleDialogSave}
                />
            )
        } else if (state.criteriaDialog.type === CriteriaType.ObservationPeriod) {
            // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
            return (
                <ObservationPeriodDialog
                    onCancel={handleDataTypeDialogCancel}
                    onSave={handleObservationPeriodDialogSave}
                    initialValue={
                        state.criteriaDialog.action.type === CohortTreeActionType.Update
                            ? state.criteriaDialog.action.filters[0]
                            : // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
                              ({ table: 'observation_period', values: ['EHR, Medical Claims, and Pharmacy Claims'] } as QueryFilterBase)
                    }
                />
            )
        } else if (state.criteriaDialog.type === CriteriaType.EhrNotes) {
            return null
        } else {
            // Handle all non-patient attributes dialogs with the RefTreeDialog
            const initialValue = state.criteriaDialog.action.type === CohortTreeActionType.Update ? state.criteriaDialog.action.filters : []
            let fieldMappers: RefFieldMapperConfig<any>[]
            switch (state.criteriaDialog.type) {
                case CriteriaType.Diagnosis:
                    fieldMappers = DIAGNOSIS_REF_FIELD_MAPPER_CONFIG
                    break
                case CriteriaType.LabTest:
                    fieldMappers = LAB_REF_FIELD_MAPPER_CONFIG
                    break
                case CriteriaType.Medication:
                    fieldMappers = MEDICATION_REF_FIELD_MAPPER_CONFIG
                    break
                case CriteriaType.Observation:
                    fieldMappers = OBSERVATION_REF_FIELD_MAPPER_CONFIG
                    break
                case CriteriaType.Procedure:
                    fieldMappers = PROCEDURE_REF_FIELD_MAPPER_CONFIG
                    break
                case CriteriaType.ExternalCohort:
                    fieldMappers = EXTERNAL_COHORT_REF_FIELD_MAPPER_CONFIG
                    break
                default:
                    fieldMappers = []
            }
            const RefTreeDialog = createRefTreeDialog(permissions)

            return (
                <RefTreeDialog
                    initialValue={initialValue}
                    fieldMappers={fieldMappers}
                    onCancel={handleDialogCancel}
                    onSave={handleDialogSave}
                    onHandleRemoveQualifiers={handleDialogRemoveQualifiers}
                    criteriaType={state.criteriaDialog.type}
                />
            )
        }
    }, [
        state.criteriaDialog,
        permissions,
        handleDialogCancel,
        handleDialogSave,
        handleDataTypeDialogCancel,
        handleObservationPeriodDialogSave,
        handleDialogRemoveQualifiers
    ]) // Explicitly exclude state.sizeLoading

    // Similarly memoize other dialogs
    const relationDialog = useMemo(() => {
        if (state.criteriaRelationDialog.type !== undefined) {
            if (state.criteriaRelationDialog.type === CriteriaRelationType.Date && state.criteriaRelationDialog.criteria.reference?.dateRelation) {
                const initialValue = state.criteriaRelationDialog.criteria.reference.dateRelation
                const criteria = state.criteriaRelationDialog.criteria
                return (
                    <DateRelationDialog
                        initialValue={initialValue}
                        criteria={criteria}
                        onCancel={handleRelationDialogCancel}
                        onSave={handleDateRelationDialogSave}
                    />
                )
            } else if (
                state.criteriaRelationDialog.type === CriteriaRelationType.FollowUp &&
                state.criteriaRelationDialog.criteria.reference?.followUpRelation
            ) {
                const initialValue = state.criteriaRelationDialog.criteria.reference.followUpRelation
                const criteria = state.criteriaRelationDialog.criteria
                return (
                    <FollowUpRelationDialog
                        initialValue={initialValue}
                        criteria={criteria}
                        onCancel={handleRelationDialogCancel}
                        onSave={handleFollowUpRelationDialogSave}
                    />
                )
            }
        }
        return null
    }, [state.criteriaRelationDialog, handleRelationDialogCancel, handleDateRelationDialogSave, handleFollowUpRelationDialogSave])

    const occurrenceDialog = useMemo(() => {
        return state.occurrenceEditDialog.target === undefined ? null : <OccurrenceEditDialog />
    }, [state.occurrenceEditDialog])

    const recencyDialog = useMemo(() => {
        return state.recencyEditDialog.target === undefined ? null : <RecencyEditDialog />
    }, [state.recencyEditDialog])

    const patientAgeDialog = useMemo(() => {
        return state.patientAgeEditDialog.target === undefined ? null : <PatientAgeEditDialog />
    }, [state.patientAgeEditDialog])

    const specialtyDialog = useMemo(() => {
        return state.specialtyEditDialog?.target === undefined ? null : <SpecialtyEditDialog />
    }, [state.specialtyEditDialog])

    const followUpLengthDialog = useMemo(() => {
        return state.followUpLengthEditDialog?.target === undefined ? null : <FollowUpLengthEditDialog />
    }, [state.followUpLengthEditDialog])

    const observationScoreEditDialog = useMemo(() => {
        return state.observationScoreEditDialog?.target === undefined ? null : <ObservationScoreEditDialog />
    }, [state.observationScoreEditDialog])

    const labNumericQualifierEditDialog = useMemo(() => {
        return state.labNumericQualifierEditDialog?.target === undefined ? null : <LabNumericQualifierEditDialog />
    }, [state.labNumericQualifierEditDialog])

    const sqlDialog = useMemo(() => {
        return sqlDialogVisible ? <CohortSqlDialog cohortId={state.cohort.id} onCancel={handleSqlDialogClose} /> : null
    }, [sqlDialogVisible, state.cohort.id, handleSqlDialogClose])

    const breadcrumbs = (
        <Box>
            <Breadcrumbs separator='›' aria-label='breadcrumb'>
                <BreadcrumbLink
                    to={`/${CohortRoutes.COHORTBUILD}/${CohortRoutes.DASHBOARD}/${
                        state.cohort.isSystem ? CohortRoutes.DATASETS : CohortRoutes.CUSTOM
                    }/${state.cohort.id}`}
                    sx={{ fontWeight: '600', fontSize: '16px', color: '#012D72' }}
                >
                    <Trans>Dashboard</Trans>
                </BreadcrumbLink>
                <BreadcrumbLink
                    to={`/${CohortRoutes.COHORTBUILD}/${state.cohort.isSystem ? CohortRoutes.DATASETS : CohortRoutes.CUSTOM}/${state.cohort.id}`}
                    sx={{ fontWeight: '600', fontSize: '16px', color: '#012D72' }}
                >
                    {state.cohort.name.charAt(0).toUpperCase() + state.cohort.name.slice(1).toLowerCase()}
                </BreadcrumbLink>
                <Box sx={{ fontWeight: '600', fontSize: '16px', color: '#707171' }}>
                    <Trans>Edit</Trans>
                </Box>
            </Breadcrumbs>
        </Box>
    )

    let cardActions: JSX.Element[] = []
    const ViewSqlButton = renderViewSqlButton()
    const ChatButton = renderChatButton()
    const AttritionFunnelButton = renderAttritionFunnelButton()
    const ReportsButton = renderReportsButton()
    if (ChatButton) {
        cardActions.push(ChatButton)
    }
    const RefreshSizeButton = renderRefreshCountButton()
    if (ViewSqlButton) {
        cardActions.push(ViewSqlButton)
    }
    return (
        <>
            <Box display='flex' flexDirection='column' gap={2} sx={{ overflowX: 'hidden' }}>
                {breadcrumbs}
                <Divider sx={{ width: '100vw', marginLeft: '-60px', marginTop: '5px' }} />
                <Box sx={{ paddingBottom: '20px' }}>
                    <CohortDashboardCohortCard
                        cohort={state.cohort}
                        actions={[...cardActions, AttritionFunnelButton, ReportsButton, RefreshSizeButton]}
                        excludeTitleRowFromCard={true}
                        onSettingsGearClick={() => setEditModalVisible(true)}
                        showDescription={true}
                        sizeLoading={state.sizeLoading}
                        onDescriptionChange={(value: string) => {
                            actions.cohortUpdate({ ...state.cohort, description: value }, true)
                        }}
                    />
                </Box>
            </Box>
            <CohortStickyHeader>
                <Grid container display='flex' alignItems='center'>
                    <Grid xs item display='flex' flexWrap='wrap' gap={1}>
                        {CRITERIA_ORDER.filter(isCriteriaPillShown).map((criteria) => (
                            <Box key={criteria}>
                                <CriteriaOption
                                    criteriaType={criteria}
                                    onDragStart={actions.cohortDragStart}
                                    onDragEnd={actions.cohortDragEnd}
                                    disabled={!isCriteriaPillEnabled(criteria)}
                                />
                            </Box>
                        ))}
                    </Grid>
                    <Box ml={2}>
                        <PatientCount cohort={state.cohort} loading={state.sizeLoading} />
                    </Box>
                </Grid>
            </CohortStickyHeader>
            {!state.cohortLoading && (
                <div>
                    <CriteriaArea
                        title={<Trans>Inclusion Criteria</Trans>}
                        root={inclusionRoot}
                        actions={actions}
                        dragState={state.dragState}
                        onAdd={handleInclusionBlockAdd}
                        attritionFunnelLabels={attritionInclusionLabels}
                    />
                    <CriteriaArea
                        title={<Trans>Exclusion Criteria</Trans>}
                        root={exclusionRoot}
                        actions={actions}
                        dragState={state.dragState}
                        onAdd={handleExclusionBlockAdd}
                        attritionFunnelLabels={attritionExclusionLabels}
                    />
                </div>
            )}
            {state.cohortLoading && (
                <Box display='flex' flexDirection='column' gap={5} mt={4}>
                    <Skeleton animation='wave' variant='rounded' width='100%' height={140} />
                    <Skeleton animation='wave' variant='rounded' width='100%' height={140} />
                </Box>
            )}
            {!state.cohortLoading && (
                <Box display='flex' gap={2} mt={2}>
                    {!inclusionRoot && (
                        <div>
                            <Button type='button' variant='text' startIcon={<AddCircleIcon />} onClick={handleInclusionBlockAdd}>
                                <Trans>Add Inclusion Criteria</Trans>
                            </Button>
                        </div>
                    )}
                </Box>
            )}
            {criteriaDialog}
            {relationDialog}
            {occurrenceDialog}
            {recencyDialog}
            {patientAgeDialog}
            {specialtyDialog}
            {followUpLengthDialog}
            {sqlDialog}
            {observationScoreEditDialog}
            {labNumericQualifierEditDialog}
            {editModalVisible && (
                <EditCohortDialogComponent
                    cohort={state.cohort}
                    onCancel={() => setEditModalVisible(false)}
                    onSave={handleUpdateCohort}
                    isLoading={state.updateLoading}
                />
            )}
        </>
    )
}

interface CriteriaOptionProps {
    criteriaType: CriteriaType
    disabled?: boolean
    onDragStart: (params: { allowRelate: boolean }) => void
    onDragEnd: () => void
}

export function relateIsSourceAllowed(criteriaType: CriteriaType): boolean {
    return (
        criteriaType !== CriteriaType.PatientAttributes &&
        criteriaType !== CriteriaType.ExternalCohort &&
        criteriaType !== CriteriaType.ObservationPeriod
    )
}

/**
 * A draggable element that represents a single criterion type. Used to add new criterion to a cohort.
 */
export const CriteriaOption: React.FunctionComponent<CriteriaOptionProps> = ({ criteriaType, onDragStart, onDragEnd, disabled }) => {
    const preview = useRef(null)
    const {
        dragProps: { color, ...dragProps }
    } = useDrag({
        onDragStart() {
            onDragStart({ allowRelate: relateIsSourceAllowed(criteriaType) })
        },
        onDragEnd() {
            onDragEnd()
        },
        getAllowedDropOperations() {
            return ['copy']
        },
        getItems() {
            return [{ custom: JSON.stringify({ type: criteriaType }) }]
        },
        preview
    })

    const icon = <CriteriaTypeIcon criteriaType={criteriaType} sx={{ width: 18, height: 18 }} isPill={true} />

    const commonButtonStyles: SxProps = {
        borderRadius: 24,
        paddingY: 0.5,
        paddingX: 2.5,
        fontWeight: 'bold',
        fontSize: 12
    }

    return (
        <>
            <Button
                {...dragProps}
                role='button'
                tabIndex={0}
                component='div'
                variant='contained'
                disabled={disabled}
                startIcon={icon}
                sx={{
                    '&:hover .cohort-builder-criteria-drag-drop-hint': { display: 'flex' },
                    ...commonButtonStyles
                }}
            >
                <CriteriaTypeLabel criteriaType={criteriaType} />
                <Box
                    className='cohort-builder-criteria-drag-drop-hint'
                    position='absolute'
                    top={0}
                    bottom={0}
                    left={0}
                    right={0}
                    borderRadius={24}
                    display='none'
                    alignItems='center'
                    justifyContent='center'
                    bgcolor={(theme) => alpha(theme.palette.primary.main, 0.8)}
                    color='common.white'
                    sx={{ textAlign: 'center', textTransform: 'none', lineHeight: 1 }}
                >
                    <Trans>
                        Click and Hold to <br /> Drag and Drop
                    </Trans>
                </Box>
            </Button>
            <DragPreview ref={preview}>
                {() => (
                    <Button
                        role='button'
                        tabIndex={0}
                        component='div'
                        variant='contained'
                        startIcon={icon}
                        sx={{ width: 'auto', ...commonButtonStyles }}
                    >
                        <CriteriaTypeLabel criteriaType={criteriaType} />
                    </Button>
                )}
            </DragPreview>
        </>
    )
}

const isOpenDialog = (dialogState: CriteriaFiltersDialogState): dialogState is CriteriaFiltersDialogOpenState => {
    return (dialogState as CriteriaFiltersDialogOpenState).action !== undefined
}
