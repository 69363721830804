import { ExplorerCohortsService } from '@om1/falcon-api'
import { handleApiError } from '@om1/falcon-api/utils'
import { notificationActions } from '@om1/platform-notifications'
import { call, put } from 'redux-saga/effects'
import { queryClient } from '../../../../apps/platform/src/query-utils'
import { cohortDeleteActions, cohortListActions } from '../state'
export function createDeleteCohortSaga() {
    return function* (action: ReturnType<typeof cohortDeleteActions.cohortDelete>) {
        try {
            yield put(cohortDeleteActions.loadingSet({ loading: true }))
            yield call(ExplorerCohortsService.deleteExplorerCohortsCohortIdDelete, {
                cohortId: action.payload.id
            })
            yield put(notificationActions.success())
            if (action.payload.refreshList) {
                // Reload current cohort page and
                // TODO should handle this with a connected routing action in the future

                yield call([queryClient, queryClient.clear])

                yield put(
                    cohortListActions.cohortsGetUserCohorts({
                        query: {
                            USER: { page: action.payload?.page, pageSize: 25, searchValue: action.payload?.search, deletedId: action.payload?.id }
                        },
                        path: {}
                    })
                )

                // window.location.href = `/${CohortRoutes.COHORTBUILD}/${CohortRoutes.DASHBOARD}/${CohortRoutes.CUSTOM}`
            }
        } catch (error: any) {
            // Handle lock error by posting the error message
            if (error.status === 423) {
                yield put(notificationActions.error(error.body['detail'][0]['msg']))
            } else {
                yield handleApiError(error)
            }
        } finally {
            yield put(cohortDeleteActions.loadingSet({ loading: false }))
        }
    }
}
