/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { ChatModel } from '@om1/falcon-api/codegen/client/models/ChatModel'
import { useConversation } from '@om1/falcon-api/codegen/hooks/useAssistantConversation'
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { NodeTypes } from '../components/query/QueryBlock'
import { Split } from '../components/Split'
import { AssistantConversation } from './AssistantConversation'
import { AssistantQuery } from './AssistantQuery'
import { Header } from './Header'

export type SelectedBlockProps = {
    selectedBlock?: NodeTypes | undefined
    setSelectedBlock?: Dispatch<SetStateAction<NodeTypes | undefined>>
}

export function ChatView({ match }) {
    const { conversationId } = match.params
    // Memoize the parameters to ensure stable query keys
    const conversationParams = useMemo(() => ({ id: conversationId, model: 'gpt-4o' as ChatModel }), [conversationId])

    const { isLoading, assistantConversationDetail } = useConversation(conversationParams)
    const [updateCohortCount, setUpdateCohortCount] = useState(0)
    useEffect(() => {
        setUpdateCohortCount((prev) => prev + 1)
    }, [])

    // Use the type for the state and setter
    const [selectedBlock, setSelectedBlock] = useState<NodeTypes | undefined>(undefined)

    if (isLoading) {
        return (
            <div style={{ minHeight: '100vh', backgroundColor: '#F5F5F5', padding: '2rem', display: 'flex' }}>
                <div style={{ flex: 2, margin: '0 auto' }}>
                    <div style={{ animation: 'pulse 1.5s infinite', gap: '1rem', display: 'flex', flexDirection: 'column' }}>
                        <div style={{ height: '2rem', backgroundColor: '#e2e8f0', borderRadius: '0.5rem', width: '25%' }}></div>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '0.75rem', height: '100%' }}>
                            <div style={{ height: '6rem', backgroundColor: '#e2e8f0', borderRadius: '0.5rem' }}></div>
                            <div style={{ height: '6rem', backgroundColor: '#e2e8f0', borderRadius: '0.5rem' }}></div>
                            <div style={{ height: '6rem', backgroundColor: '#e2e8f0', borderRadius: '0.5rem' }}></div>
                            <div style={{ height: '8rem', backgroundColor: '#e2e8f0', borderRadius: '0.5rem', marginTop: '37.5%' }}></div>
                        </div>
                    </div>
                </div>

                <div style={{ flex: 1, marginLeft: '1rem', backgroundColor: '#e2e8f0', borderRadius: '0.5rem', padding: '1rem' }}>
                    <div style={{ animation: 'pulse 1.5s infinite', height: '100%', borderRadius: '0.5rem' }}></div>
                </div>
            </div>
        )
    }

    return (
        <div style={{ backgroundColor: '#F5F5F5', display: 'flex', flexDirection: 'column' }}>
            <Header conversationId={conversationId} />
            <Split sizes={[67, 33]} minSize={[400, 400]}>
                <AssistantConversation
                    conversationId={conversationId}
                    onUpdateCohort={() => setUpdateCohortCount(updateCohortCount + 1)}
                    selectedBlock={selectedBlock}
                    setSelectedBlock={setSelectedBlock}
                />
                {assistantConversationDetail?.assistantCohortId ? (
                    <AssistantQuery
                        id={assistantConversationDetail?.assistantCohortId}
                        updateCohortCount={updateCohortCount}
                        selectedBlock={selectedBlock}
                        setSelectedBlock={setSelectedBlock}
                    />
                ) : (
                    <></>
                )}
            </Split>
        </div>
    )
}

export default withRouter(ChatView)
