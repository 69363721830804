import { GetNextPageParamFunction, useInfiniteQuery } from '@tanstack/react-query'
import { ExplorerAnalyticsRefService, PaginatedDTO_RefResultDTO_, RefTable } from '../client'

export const QUERY_KEYS = {
    refLabTable: 'refLabTable'
} as const

export function useRefTable(refTableName: RefTable, filters?: string[], limit = 100, enabled = true) {
    const getNextPageParam: GetNextPageParamFunction<PaginatedDTO_RefResultDTO_> = (lastPage) => {
        return lastPage.meta.currentPage < lastPage.meta.totalPages ? lastPage.meta.currentPage + 1 : undefined
    }

    return useInfiniteQuery<PaginatedDTO_RefResultDTO_>({
        queryKey: [QUERY_KEYS.refLabTable, refTableName, limit, filters],
        queryFn: () => ExplorerAnalyticsRefService.indexExplorerAnalyticsRefRefTableNameGet({ refTableName, limit, filters }),
        getNextPageParam,
        enabled
    })
}
