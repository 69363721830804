/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { t } from '@lingui/macro'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'
import { IngestConversationDTO } from '@om1/falcon-api/codegen/client'
import {
    useCreateConversation,
    useDeleteConversation,
    useIngestConversations,
    useUpdateConversation
} from '@om1/falcon-api/codegen/hooks/useIngestConversations'
import { formatDistanceToNow } from 'date-fns'
import { MessageCircle, MessageSquarePlus, Pencil, Trash2 } from 'lucide-react'
import React, { useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

interface ConversationListComponentProps extends RouteComponentProps {
    onConversationSelect?: (id: string) => void
    onStartChat?: (id: string) => void
    selectedConversationId?: string | null
}

export function IngestConversationListComponent({
    history,
    onConversationSelect,
    onStartChat,
    selectedConversationId
}: ConversationListComponentProps) {
    const { ingestConversations, fetchNextPage, hasNextPage, isFetchingNextPage } = useIngestConversations({ page: 1, limit: 10 })
    const createConversation = useCreateConversation()
    const deleteConversation = useDeleteConversation()
    const updateConversation = useUpdateConversation()
    const [hoveredConversation, setHoveredConversation] = useState<string | null>(null)
    const [editDialogOpen, setEditDialogOpen] = useState(false)
    const [editingConversation, setEditingConversation] = useState<{ id: string; title: string } | null>(null)
    const [newTitle, setNewTitle] = useState('')

    const handleCreateConversation = async () => {
        try {
            const result = await createConversation.mutateAsync({
                requestBody: {
                    title: t`New Conversation`
                }
            })
            if (result.id) {
                history.push(`/ingest/conversation/${result.id}`)
            }
        } catch (error) {
            console.error('Error creating conversation:', error)
        }
    }

    const handleDeleteConversation = async (id: string) => {
        try {
            await deleteConversation.mutateAsync({ id })
        } catch (error) {
            console.error('Error deleting conversation:', error)
        }
    }

    const handleConversationClick = (id: string) => {
        if (onConversationSelect) {
            onConversationSelect(id)
        } else {
            history.push(`/ingest/conversation/${id}`)
        }
    }

    const handleEditClick = (e: React.MouseEvent<HTMLButtonElement>, conversation: { id: string; title: string }) => {
        e.stopPropagation()
        setEditingConversation(conversation)
        setNewTitle(conversation.title || '')
        setEditDialogOpen(true)
    }

    const handleEditSave = async () => {
        if (editingConversation && newTitle.trim()) {
            try {
                await updateConversation.mutateAsync({
                    id: editingConversation.id,
                    requestBody: {
                        title: newTitle.trim()
                    }
                })
                setEditDialogOpen(false)
                setEditingConversation(null)
                setNewTitle('')
            } catch (error) {
                console.error('Error updating conversation:', error)
            }
        }
    }

    return (
        <div
            style={{
                padding: '1.5rem',
                margin: '0 auto',
                backgroundColor: '#F5F7FA',
                width: '100%'
            }}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '2rem',
                    borderBottom: '1px solid #E2E8F0',
                    paddingBottom: '1rem'
                }}
            >
                <h1
                    style={{
                        margin: 0,
                        fontSize: '1.875rem',
                        fontWeight: 600,
                        color: '#1A202C'
                    }}
                >{t`Ingest Conversations`}</h1>
                <button
                    onClick={handleCreateConversation}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.5rem',
                        padding: '0.75rem 1.25rem',
                        border: 'none',
                        borderRadius: '0.75rem',
                        backgroundColor: '#0A84FF',
                        color: 'white',
                        cursor: 'pointer',
                        fontSize: '0.9375rem',
                        fontWeight: 500,
                        transition: 'all 0.2s ease-in-out',
                        boxShadow: '0 1px 3px rgba(0,0,0,0.1)'
                    }}
                >
                    <MessageSquarePlus size={20} />
                    {t`New Conversation`}
                </button>
            </div>

            <div style={{ display: 'grid', gap: '1rem' }}>
                {ingestConversations?.pages.map((page) =>
                    page.data.map((conversation: IngestConversationDTO) => (
                        <div
                            key={conversation.id}
                            onMouseEnter={() => setHoveredConversation(conversation.id)}
                            onMouseLeave={() => setHoveredConversation(null)}
                            onClick={() => handleConversationClick(conversation.id)}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '1rem',
                                padding: '1rem',
                                backgroundColor: selectedConversationId === conversation.id ? '#F0F7FF' : 'white',
                                borderRadius: '1rem',
                                cursor: 'pointer',
                                border: '1px solid #E2E8F0',
                                transition: 'all 0.2s ease-in-out',
                                boxShadow: hoveredConversation === conversation.id ? '0 4px 6px -1px rgba(0, 0, 0, 0.1)' : 'none',
                                transform: hoveredConversation === conversation.id ? 'translateY(-1px)' : 'none'
                            }}
                        >
                            <div
                                style={{
                                    width: '3rem',
                                    height: '3rem',
                                    borderRadius: '0.75rem',
                                    backgroundColor: '#F0F7FF',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                <MessageCircle size={24} color='#0A84FF' />
                            </div>
                            <div style={{ flex: 1 }}>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        marginBottom: '0.25rem'
                                    }}
                                >
                                    <h3
                                        style={{
                                            margin: 0,
                                            fontSize: '1rem',
                                            fontWeight: 500,
                                            color: '#1A202C'
                                        }}
                                    >
                                        {conversation.title || t`Untitled Conversation`}
                                    </h3>
                                    <span
                                        style={{
                                            fontSize: '0.875rem',
                                            color: '#718096'
                                        }}
                                    >
                                        {t`Created`} {formatDistanceToNow(new Date(conversation.createdDttm), { addSuffix: true })}
                                    </span>
                                </div>
                                <div
                                    style={{
                                        fontSize: '0.875rem',
                                        color: '#718096',
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '0.5rem'
                                    }}
                                >
                                    <span>
                                        {t`Created by`} {conversation.createdByEmail}
                                    </span>
                                    <span>•</span>
                                    <span>
                                        {t`Last updated`} {formatDistanceToNow(new Date(conversation.updatedDttm), { addSuffix: true })}
                                    </span>
                                </div>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    gap: '0.5rem',
                                    opacity: hoveredConversation === conversation.id ? 1 : 0,
                                    transition: 'opacity 0.2s ease-in-out'
                                }}
                            >
                                <button
                                    onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                                        handleEditClick(e, { ...conversation, title: conversation.title || '' })
                                    }
                                    style={{
                                        padding: '0.5rem',
                                        border: 'none',
                                        borderRadius: '0.5rem',
                                        backgroundColor: '#F7FAFC',
                                        color: '#4A5568',
                                        cursor: 'pointer',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        transition: 'all 0.2s ease'
                                    }}
                                    onMouseEnter={(e) => {
                                        e.currentTarget.style.backgroundColor = '#EDF2F7'
                                        e.currentTarget.style.color = '#2D3748'
                                    }}
                                    onMouseLeave={(e) => {
                                        e.currentTarget.style.backgroundColor = '#F7FAFC'
                                        e.currentTarget.style.color = '#4A5568'
                                    }}
                                >
                                    <Pencil size={16} />
                                </button>
                                <button
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        handleDeleteConversation(conversation.id)
                                    }}
                                    style={{
                                        padding: '0.5rem',
                                        border: 'none',
                                        borderRadius: '0.5rem',
                                        backgroundColor: '#FEE2E2',
                                        color: '#DC2626',
                                        cursor: 'pointer',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        transition: 'all 0.2s ease'
                                    }}
                                    onMouseEnter={(e) => {
                                        e.currentTarget.style.backgroundColor = '#FEE2E2'
                                        e.currentTarget.style.color = '#B91C1C'
                                    }}
                                    onMouseLeave={(e) => {
                                        e.currentTarget.style.backgroundColor = '#FEE2E2'
                                        e.currentTarget.style.color = '#DC2626'
                                    }}
                                >
                                    <Trash2 size={16} />
                                </button>
                            </div>
                        </div>
                    ))
                )}
            </div>

            {hasNextPage && (
                <button
                    onClick={() => fetchNextPage()}
                    disabled={isFetchingNextPage}
                    style={{
                        marginTop: '1.5rem',
                        padding: '0.75rem 1.5rem',
                        border: '1px solid #E2E8F0',
                        borderRadius: '0.75rem',
                        backgroundColor: 'white',
                        color: '#4A5568',
                        cursor: 'pointer',
                        width: '100%',
                        fontSize: '0.9375rem',
                        fontWeight: 500,
                        transition: 'all 0.2s ease-in-out',
                        opacity: isFetchingNextPage ? 0.7 : 1
                    }}
                >
                    {isFetchingNextPage ? t`Loading more...` : t`Load more`}
                </button>
            )}

            <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)} maxWidth='sm' fullWidth>
                <DialogTitle>Edit Conversation Title</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin='dense'
                        label='Title'
                        type='text'
                        fullWidth
                        value={newTitle}
                        onChange={(e) => setNewTitle(e.target.value)}
                        variant='outlined'
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setEditDialogOpen(false)} color='inherit'>
                        Cancel
                    </Button>
                    <Button onClick={handleEditSave} variant='contained' color='primary' disabled={!newTitle.trim() || updateConversation.isLoading}>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export const IngestConversationList = withRouter(IngestConversationListComponent)
