import { t, Trans } from '@lingui/macro'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import ErrorIcon from '@mui/icons-material/Error'
import FilterAltOffOutlinedIcon from '@mui/icons-material/FilterAltOffOutlined'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined'
import { Box, Button, CircularProgress, Collapse, Grid, Paper, SxProps, Typography } from '@mui/material'
import { useUserPermissions } from '@om1/platform-authentication'
import { BasicCardStat } from '@om1/platform-components/BasicCard'
import BasicTable from '@om1/platform-components/BasicTable'
import ReportChartButtons from '@om1/platform-components/ReportChartButtons'
import { Routes, toPath } from '@om1/platform-routing'
import { PlatformPermissions, RoutedFrameworkComponentProps } from '@om1/platform-utils'
import Highcharts, { AxisLabelsFormatterCallbackFunction, Point } from 'highcharts'
import { HighchartsReact } from 'highcharts-react-official'
import highchartsAccessibility from 'highcharts/modules/accessibility'
import drilldown from 'highcharts/modules/drilldown'
import { get } from 'lodash'
import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { CohortRoutes } from '../../routes'
import {
    AnalyticsChartData,
    AnalyticsDataState,
    AnalyticsSectionData,
    AnalyticsTaskStatus,
    CohortListItem,
    CriteriaType,
    DimensionGroupingType,
    FilterDTO,
    QueryFilterBase,
    Report,
    ReportAnalyticsType,
    ReportName,
    ReportQueryFilters,
    reportsActions,
    ReportType
} from '../../state'
import { RefFieldMapperConfig } from '../edit/base/CohortEditComponent'
import { createRefTreeDialog } from '../edit/blocks/RefTreeDialog'
import { DRILLDOWN_SECTION_ID, DRILLDOWN_SUMMARY_SECTION_ID, REPORT_SECTION_ID } from '../edit/utils/filter-utils'
import { CriteriaTypeLabel } from '../shared/CriteriaTypeLabel'
import { DeleteReportDialog } from './DeleteReportDialog'

// init the modules
highchartsAccessibility(Highcharts)
drilldown(Highcharts)

export interface ReportChartConfig {
    mappers: RefFieldMapperConfig<any>[]
    type: CriteriaType
    groupingType: DimensionGroupingType
    chartLabelKeys: string[]
}

export type ReportAnalyticsTabType =
    | ReportAnalyticsType.DIAGNOSES
    | ReportAnalyticsType.MEDICATIONS
    | ReportAnalyticsType.OBSERVATIONS
    | ReportAnalyticsType.PROCEDURES
    | ReportAnalyticsType.LAB_TESTS

export type ReportChartComponentProps = RoutedFrameworkComponentProps<
    { reportAnalyticsType?: ReportAnalyticsType },
    { cohortId: string },
    {
        reports: Report[]
        reportsLoading: boolean
        analytics?: AnalyticsDataState<AnalyticsChartData>
        permissions?: string[]
    },
    typeof reportsActions,
    {
        config: ReportChartConfig
        cohort: CohortListItem
        reportAnalyticsType: ReportAnalyticsTabType
        renderInDashboard?: boolean
        disableFetching?: boolean
        chartRefProp?: React.MutableRefObject<Highcharts.Chart | null>
    }
>

const CONTAINER_PROPS = {
    style: {
        width: '100%',
        height: '100%',
        boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)', // Add shadow to match design
        borderRadius: '8px', // Rounded corners to match design
        overflow: 'hidden'
    }
}

// Highcharts Y axes don't format with a thousands seperator, so override it in that case.
const axisLabelFormatter: AxisLabelsFormatterCallbackFunction = (context) => {
    let label = context.axis.defaultLabelFormatter.call(context)
    // Use thousands separator for four-digit numbers too
    if (/^[0-9]{4}$/.test(label)) {
        label = Highcharts.numberFormat(Number(context.value), 0)
    }
    return label
}

const BAR_CHART_OPTIONS: Highcharts.Options = {
    chart: {
        inverted: false,
        backgroundColor: 'transparent',
        style: {
            // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
            fontFamily: "'Arial', sans-serif" // match the font style
        },
        borderRadius: 5,
        animation: false
    },
    title: {
        text: undefined
    },
    yAxis: {
        min: 0,
        max: null,
        title: undefined,
        labels: {
            overflow: 'justify',
            formatter: axisLabelFormatter
        }
    },
    legend: {
        enabled: false
    },
    credits: undefined,
    series: [
        {
            type: 'column',
            // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
            color: '#002D72',
            groupPadding: 0.1,
            dataLabels: {
                enabled: true
            }
        }
    ],
    plotOptions: {
        column: {
            borderRadius: 5, // Match border radius
            borderWidth: 0, // Remove border or set it to match your design
            dataLabels: {
                enabled: true,
                style: {
                    textOutline: '0', // Remove text outline
                    fontWeight: 'bold' // Set to bold or match your design
                }
            }
        }
    },
    drilldown: {
        activeAxisLabelStyle: {
            textDecoration: 'none',
            fontWeight: 'regular',
            color: 'black',
            zIndex: 1
        },
        activeDataLabelStyle: {
            textDecoration: 'none',
            fontWeight: 'bold',
            color: 'black',
            zIndex: 1
        },
        breadcrumbs: {
            position: {
                align: 'right'
            }
        },
        animation: false
    }
}

export type ChartData = Record<string, number>

export type DrilldownData = { name: string; id: string; type: string; inverted: boolean; data: (string | number)[][] }

export enum DrilldownDataValueTypes {
    STRING = 'string',
    NUMERIC = 'numeric',
    DATE = 'date'
}

/**
 * A component for selecting filter criteria and rendering a chart based on a given configuration.
 */
export const ReportChartComponent: React.FunctionComponent<ReportChartComponentProps> = ({
    state: { analytics, reports, reportsLoading },
    actions,
    props: {
        cohort,
        reportAnalyticsType,
        config: { mappers, type, chartLabelKeys },
        renderInDashboard,
        disableFetching,
        chartRefProp
    }
}) => {
    const innerChartRef = useRef<Highcharts.Chart | null>(null)
    const chartRef = chartRefProp || innerChartRef
    const [view, setView] = useState<'barChart' | 'tableChart'>('barChart')
    const [selectedDrilldownId, setSelectedDrilldownId] = useState<string | null>()
    const [dialogOpen, setDialogOpen] = useState(false)
    const [refreshingData, setRefreshingData] = useState(false)
    const { permissions } = useUserPermissions()

    // Store cohort ID in a ref to prevent unnecessary rerenders when only size changes
    const cohortIdRef = useRef<string>(cohort.id)

    // Update the ref only when the ID actually changes
    useEffect(() => {
        if (cohortIdRef.current !== cohort.id) {
            cohortIdRef.current = cohort.id
        }
    }, [cohort.id])

    const canManageReports = useMemo(
        () => !cohort.isSystem || (permissions || []).includes(PlatformPermissions.MANAGE_SYSTEM_COHORT_REPORTS),
        [cohort.isSystem, permissions]
    )

    const reportType = useMemo(() => {
        switch (reportAnalyticsType) {
            case ReportAnalyticsType.DIAGNOSES: {
                return ReportType.DIAGNOSIS
            }
            case ReportAnalyticsType.MEDICATIONS: {
                return ReportType.MEDICATION
            }
            case ReportAnalyticsType.OBSERVATIONS: {
                return ReportType.OBSERVATION
            }
            case ReportAnalyticsType.PROCEDURES: {
                return ReportType.PROCEDURE
            }
            case ReportAnalyticsType.LAB_TESTS: {
                return ReportType.LAB_TEST
            }
        }
    }, [reportAnalyticsType])

    const reportName = useMemo(() => {
        switch (reportAnalyticsType) {
            case ReportAnalyticsType.DIAGNOSES: {
                return ReportName.DIAGNOSIS
            }
            case ReportAnalyticsType.MEDICATIONS: {
                return ReportName.MEDICATION
            }
            case ReportAnalyticsType.OBSERVATIONS: {
                return ReportName.OBSERVATION
            }
            case ReportAnalyticsType.PROCEDURES: {
                return ReportName.PROCEDURE
            }
            case ReportAnalyticsType.LAB_TESTS: {
                return ReportType.LAB_TEST
            }
        }
    }, [reportAnalyticsType])

    const report = useMemo(
        () => reports.find((report) => report.cohortId === cohort.id && report.reportType === reportType),
        [reports, cohort, reportType]
    )

    useEffect(() => {
        if (report && refreshingData) {
            actions.reportTaskGet({ path: { cohortId: report.cohortId, reportId: report.id, reportType: reportAnalyticsType }, query: {} })
        }
        setView('barChart')
    }, [actions, refreshingData, report, reportAnalyticsType])

    const filters = useMemo<QueryFilterBase[]>(() => {
        // null check and find the bar chart section
        const barChartSection = get(report, 'sections', []).find((section) => section.id === REPORT_SECTION_ID)

        if (!barChartSection) {
            return []
        }

        return barChartSection.queries.reduce((fieldFilters: any[], query: ReportQueryFilters) => {
            if (query.filters.length === 0) {
                return fieldFilters
            }

            query.filters[0].or.forEach((filter) => {
                const f: FilterDTO = filter as FilterDTO

                fieldFilters.push({
                    field: f.field,
                    operator: f.operator,
                    table: f.table,
                    values: f.values
                })
            })
            return fieldFilters
        }, [])
    }, [report])

    const analyticsLoading = useMemo(() => {
        return (analytics && analytics.status === AnalyticsTaskStatus.Pending) || (report && analytics === undefined)
    }, [analytics, report])

    useEffect(() => {
        if (report && analytics === undefined && !refreshingData) {
            actions.reportTaskGet({ path: { cohortId: report.cohortId, reportId: report.id, reportType: reportAnalyticsType }, query: {} })
        }
    }, [actions, analytics, report, reportAnalyticsType, refreshingData])

    const handleDialogCancel = () => {
        setDialogOpen(false)
    }

    const handleDialogSave = useCallback(
        (filters: QueryFilterBase[]) => {
            if (report) {
                actions.reportUpdate({ reportId: report.id, cohortId: cohort.id, filters })
            } else {
                actions.reportCreate({ cohortId: cohort.id, filters })
            }
            setRefreshingData(true)
            setDialogOpen(false)
        },
        [actions, cohort.id, report]
    )

    const chartData: ChartData = useMemo(() => {
        if (analytics) {
            const temp = {}

            get(analytics, ['data', 'sections'], []).forEach((section) => {
                if (section.id !== REPORT_SECTION_ID) {
                    return
                }

                section.results.forEach((sectionResult) => {
                    sectionResult.data.forEach((d) => {
                        const label = chartLabelKeys.find((key) => d[key] !== undefined)
                        if (label) {
                            temp[d[label]] = d.patient_count
                        }
                    })
                })
            })

            setRefreshingData(false)
            return temp
        } else {
            setRefreshingData(false)
            return {}
        }
    }, [analytics, chartLabelKeys])

    const drilldownData: DrilldownData[] = useMemo(() => {
        const drilldownSection = get(analytics, ['data', 'sections'], []).find((section) => section.id === DRILLDOWN_SECTION_ID)

        if (!drilldownSection) {
            setSelectedDrilldownId(null)
            setRefreshingData(false)
            return []
        }

        const allObservations = new Set<string>(drilldownSection.results[0].data.map((data) => `${data.observation}`))
        const drilldownResults = Array.from(allObservations).reduce((results: DrilldownData[], observation: string) => {
            results.push({
                name: `${DRILLDOWN_SECTION_ID} ${observation}`,
                id: `${DRILLDOWN_SECTION_ID} ${observation}`,
                type: 'column',
                inverted: false,
                data: drilldownSection.results[0].data
                    .filter((data) => data.observation === observation)
                    .map((d) => [d.value, d.patient_count, d.value_type])
            })

            return results
        }, [])

        setSelectedDrilldownId(null)
        setRefreshingData(false)
        return drilldownResults
    }, [analytics])

    const drilldownSummaryMap: Record<string, AnalyticsSectionData> = useMemo(() => {
        if (!analytics) {
            return {}
        }

        const summarySection = get(analytics, ['data', 'sections'], []).find((section) => section.id === DRILLDOWN_SUMMARY_SECTION_ID)

        if (!summarySection) {
            return {}
        }

        return get(summarySection, ['results', '0', 'data'], []).reduce(
            (summaryDataMap: Record<string, AnalyticsSectionData>, data: AnalyticsSectionData) => {
                summaryDataMap[data.observation] = data

                return summaryDataMap
            },
            {}
        )
    }, [analytics])

    const drilldownSummaryBar: ReactNode = useMemo(() => {
        if (!selectedDrilldownId) {
            return null
        }

        const data = drilldownSummaryMap[selectedDrilldownId] as {
            patient_count: string
            total_record_count: string
            avg_record_count: string
            stddev_record_count: string
            median_record_count: string
            twenty_fifth_percentile_record_count: string
            seventy_fifth_percentile_record_count: string
            avg_months_between: string
            stddev_months_between: string
        }

        if (!data) {
            return null
        }

        const integerFormatter = new Intl.NumberFormat('en-us', { maximumFractionDigits: 2 })
        const totalPatientsWithOutcome = integerFormatter.format(parseInt(data.patient_count))
        const totalObservations = integerFormatter.format(parseInt(data.total_record_count))
        const totalObservationsStdDev = integerFormatter.format(parseFloat(data.stddev_record_count))
        const averageObservationsPerPatient = integerFormatter.format(parseFloat(data.avg_record_count))
        const medianObservationsPerPatient = integerFormatter.format(parseInt(data.median_record_count))
        const medianObservationsPerPatientQ1 = integerFormatter.format(parseFloat(data.twenty_fifth_percentile_record_count))
        const medianObservationsPerPatientQ3 = integerFormatter.format(parseFloat(data.seventy_fifth_percentile_record_count))
        const averageMonthsBetweenObservations = integerFormatter.format(parseFloat(data.avg_months_between))
        const averageMonthsBetweenObservationsStdDev = integerFormatter.format(parseFloat(data.stddev_months_between))

        const valueProps = { fontSize: '25px' }

        return (
            <Paper elevation={2} sx={{ p: 2, mb: 2 }}>
                <Grid container spacing={2}>
                    <Grid item xs display='flex'>
                        <BasicCardStat
                            id='total-patients-with-outcome'
                            label={<Trans>Total Patients w/ Outcome</Trans>}
                            value={totalPatientsWithOutcome}
                            valueProps={valueProps}
                        />
                    </Grid>
                    <Grid item xs display='flex'>
                        <BasicCardStat
                            id='total-observations'
                            label={<Trans>Total Observations</Trans>}
                            value={totalObservations}
                            valueProps={valueProps}
                        />
                    </Grid>
                    <Grid item xs display='flex'>
                        <BasicCardStat
                            id='average-observations-per-patient'
                            label={<Trans>Average Observations Per Patient</Trans>}
                            value={averageObservationsPerPatient}
                            valueProps={valueProps}
                            // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
                            footer={`(Std dev. ${totalObservationsStdDev})`}
                        />
                    </Grid>
                    <Grid item xs display='flex'>
                        <BasicCardStat
                            id='median-observations-per-patient'
                            label={<Trans>Median Observations Per Patient</Trans>}
                            value={medianObservationsPerPatient}
                            valueProps={valueProps}
                            // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
                            footer={`(Q1, Q3: ${medianObservationsPerPatientQ1}, ${medianObservationsPerPatientQ3})`}
                        />
                    </Grid>
                    <Grid item xs display='flex'>
                        <BasicCardStat
                            id='average-months-between-observations'
                            label={<Trans>Average Months Between Observations</Trans>}
                            value={averageMonthsBetweenObservations}
                            valueProps={valueProps}
                            // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
                            footer={`(Std dev. ${averageMonthsBetweenObservationsStdDev})`}
                        />
                    </Grid>
                </Grid>
            </Paper>
        )
    }, [selectedDrilldownId, drilldownSummaryMap])

    const dataLength = Object.keys(chartData).length
    function computeDataPointHeight(dataLength) {
        if (dataLength < 5) {
            return 200
        } else if (dataLength < 10) {
            return 100
        } else if (dataLength < 20) {
            return 50
        } else if (dataLength < 40) {
            return 25
        } else {
            return 20
        }
    }
    const DATA_POINT_HEIGHT = computeDataPointHeight(dataLength)
    const containerHeightPixels = DATA_POINT_HEIGHT * dataLength
    const containerHeight = `${containerHeightPixels}px`

    const handleResize = useCallback(
        (chartRef) => {
            if (chartRef.current) {
                const chartContainer = chartRef.current.container
                if (chartContainer) {
                    chartRef.current.reflow()
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    )

    useEffect(() => {
        handleResize(chartRef)

        const resizeListener = () => {
            handleResize(chartRef)
        }

        window.addEventListener('resize', resizeListener)

        return () => {
            window.removeEventListener('resize', resizeListener)
        }
    }, [handleResize, chartRef])

    const [drillDownObject, setDrillDownObject] = useState<Highcharts.DrilldownEventObject | null>(null)

    const CHART_OPTIONS = useMemo(() => {
        const hasDrilldownData = reportType === ReportType.OBSERVATION && drilldownData.length > 0
        const values = Object.entries(chartData).sort((a, b) => b[1] - a[1])
        const categories = values.map((value) => value[0])
        let data: any
        if (hasDrilldownData) {
            data = values.map((value) => ({ name: value[0], y: value[1], drilldown: `${DRILLDOWN_SECTION_ID} ${value[0]}` }))
        } else {
            data = values.map((value) => value[1])
        }
        let options: Highcharts.Options = {
            ...BAR_CHART_OPTIONS,
            chart: { ...BAR_CHART_OPTIONS.chart, inverted: true, height: containerHeight },
            xAxis: { ...BAR_CHART_OPTIONS.xAxis, categories },
            yAxis: { ...BAR_CHART_OPTIONS.yAxis, title: { text: t`Patient Count`, align: 'middle' } },
            series: [{ ...BAR_CHART_OPTIONS.series![0], name: reportName, data: data }],
            tooltip: {
                pointFormatter: function () {
                    const point = this as any as Point // use "any" to bypass the type checking for this specific case
                    // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
                    return 'Value: ' + point.y!.toLocaleString('en-US')
                }
            }
        }
        if (hasDrilldownData) {
            options.drilldown = {
                ...BAR_CHART_OPTIONS.drilldown,
                // @ts-ignore
                series: drilldownData
            }
            options.chart!.events = {
                ...options.chart!.events,
                drilldown: function (e) {
                    setDrillDownObject(e)
                    // @ts-ignore
                    const categories = (e.seriesOptions?.data || [])
                        .sort(([aLabel, aValue, aType], [bLabel, bValue, _bType]) => {
                            switch (aType) {
                                case DrilldownDataValueTypes.NUMERIC:
                                    return Number(aLabel) - Number(bLabel)
                                case DrilldownDataValueTypes.STRING:
                                    return bValue - aValue
                                case DrilldownDataValueTypes.DATE:
                                    return parseInt(aLabel) - parseInt(bLabel)
                                default:
                                    return bValue - aValue
                            }
                        })
                        .map((c) => c[0])
                    // Update x-axis properties for the drilldown level
                    this.xAxis[0].update({ ...this.xAxis, categories }, true)
                    this.xAxis[0].update(
                        {
                            ...this.xAxis,
                            categories,
                            title: { text: t`Score`, align: 'middle' }
                        },
                        true
                    )

                    this.update({
                        chart: {
                            inverted: false
                        }
                    })

                    setSelectedDrilldownId(e.point.name)

                    // Resize based on number of categories
                    this.setSize(null, 400)
                },
                drillup: function () {
                    this.update({
                        chart: {
                            inverted: true
                        }
                    })

                    // Reset x-axis properties when drilling up
                    this.update({ chart: { inverted: true } })
                    this.xAxis[0].update(
                        {
                            ...this.xAxis,
                            categories
                        },
                        true
                    )

                    setSelectedDrilldownId(null)

                    // Reset chart size to initial height
                    this.setSize(null, containerHeightPixels)
                }
            }
        }
        return options
    }, [chartData, drilldownData, reportName, reportType, containerHeight, containerHeightPixels])

    const [deleteModalVisible, setDeleteModalVisible] = useState(false)

    const deleteButton = useMemo(() => {
        if (!canManageReports) {
            return <></>
        }

        return (
            <>
                <Box>
                    <Button
                        onClick={() => setDeleteModalVisible(true)}
                        variant='text'
                        startIcon={<DeleteForeverIcon fontSize='small' />}
                        data-testid='delete-button'
                    >
                        <Trans>Remove Filters</Trans>
                    </Button>
                </Box>
                {report && deleteModalVisible && (
                    <DeleteReportDialog
                        id={report.id}
                        actions={actions}
                        onCancel={() => setDeleteModalVisible(false)}
                        cohortId={cohort.id}
                        type={report.reportType}
                    />
                )}
            </>
        )
    }, [canManageReports, report, deleteModalVisible, actions, cohort.id])

    // Wait till report is valid and show report charts
    const renderReport = useMemo<ReactNode>(() => {
        if (!report || analyticsLoading || (report && analytics === undefined) || refreshingData) {
            return null
        }

        if (analytics?.status === AnalyticsTaskStatus.Failed) {
            return (
                <Box sx={{ maxWidth: '100%' }}>
                    <Box display='flex' justifyContent='space-between' alignItems='center'>
                        <h3>
                            <Trans>Patient Count by</Trans> <CriteriaTypeLabel criteriaType={type} />
                        </h3>
                        {canManageReports && !renderInDashboard && (
                            <Box>
                                <Button onClick={() => setDialogOpen(true)} variant='text' startIcon={<FilterAltOutlinedIcon fontSize='small' />}>
                                    <Trans>Edit Filters</Trans>
                                </Button>
                            </Box>
                        )}
                        {canManageReports && !renderInDashboard && deleteButton}
                    </Box>
                    <Box
                        paddingY={4}
                        marginBottom={3}
                        bgcolor='grey.200'
                        display='flex'
                        justifyContent='center'
                        alignItems='center'
                        flexDirection='column'
                        sx={{ position: 'relative', backgroundColor: 'grey.100' }}
                    >
                        <Box
                            width={'100%'}
                            display='flex'
                            justifyContent='center'
                            alignItems='center'
                            marginBottom={2}
                            sx={{ position: 'relative', backgroundColor: 'grey.100' }}
                        >
                            <ErrorIcon
                                sx={{
                                    width: '120px',
                                    height: '120px',
                                    color: '#002D72'
                                }}
                            />
                        </Box>
                        <Box
                            sx={{
                                paddingTop: '20px',
                                paddingLeft: '20px',
                                paddingRight: '20px',
                                paddingBottom: '0px',
                                color: '#002D72',
                                fontSize: '20px',
                                opacity: reportsLoading ? '0%' : '100%'
                            }}
                        >
                            <Trans>There was an error loading this report.</Trans>
                        </Box>
                        <Box paddingTop={5}>
                            <Button
                                onClick={() =>
                                    actions.reportTaskGet({
                                        path: { cohortId: report.cohortId, reportId: report.id, reportType: reportAnalyticsType },
                                        query: {}
                                    })
                                }
                                variant='contained'
                            >
                                <Trans>Retry</Trans>
                            </Button>
                        </Box>
                    </Box>
                </Box>
            )
        }
        function toSafeFilename(input: string, reportAnalyticsType: string, drilldownSpecifier?: string): string {
            let safeString = input.replace(/[^a-zA-Z0-9-_]/g, '_')
            let safeReportAnalyticsType = reportAnalyticsType.replace(/[^a-zA-Z0-9-_]/g, '_')
            const now = new Date()
            const formattedDate = now.toISOString().split('T')[0].replace(/-/g, '_')
            return `${safeString}_${safeReportAnalyticsType}${drilldownSpecifier !== undefined ? `_${drilldownSpecifier}` : ''}_${formattedDate}`
        }

        function convertChartDataToCSV(chartDataArray: [string, number][]): string {
            const seriesOptions = drillDownObject?.seriesOptions
            if (seriesOptions) {
                const name = seriesOptions['name']
                const headers = [name ? `"${name.replace(/^drilldown_section\s*/, '')}"` : `"Value"`, '"Count"']
                const csvRows = [headers.join(',')]
                const data = seriesOptions['data']
                if (Array.isArray(data)) {
                    data.map((item) => [item[0], item[1]]).forEach((row) => {
                        const quotedRow = row.map(
                            (field) => (typeof field === 'string' ? `"${field.replace(/"/g, '""')}"` : field) // Encapsulate strings in quotes and escape existing quotes
                        )
                        csvRows.push(quotedRow.join(','))
                    })
                }
                return csvRows.join('\n')
            } else {
                const headers = ['"Value"', '"Count"']
                const csvRows = [headers.join(',')]
                chartDataArray.forEach((row) => {
                    const quotedRow = row.map(
                        (field) => (typeof field === 'string' ? `"${field.replace(/"/g, '""')}"` : field) // Encapsulate strings in quotes and escape existing quotes
                    )
                    csvRows.push(quotedRow.join(','))
                })
                return csvRows.join('\n')
            }
        }

        // Function to trigger the download of the CSV file
        function downloadCSV(csvString: string, fileName: string) {
            const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' })
            const url = URL.createObjectURL(blob)
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        }

        const handleDownloadCSV = () => {
            let chartDataArray: [string, number][] = []
            let drilldownSpecifier: string | undefined
            if (drillDownObject) {
                chartDataArray = Object.entries((drillDownObject?.seriesOptions as any)?.['data'].map((item) => [item[0], item[1]]))
                drilldownSpecifier = (drillDownObject?.seriesOptions as any)?.['name']
                    .replace(/^drilldown_section\s*/, '')
                    .replace(/[^\w\s]/g, '') // Remove non-alphanumeric characters
                    .replace(/\s+/g, '_') // Replace spaces with underscores
                    .toLowerCase()
            } else {
                chartDataArray = Object.entries(chartData)
            }
            const csvString = convertChartDataToCSV(chartDataArray)
            let filename = `${toSafeFilename(cohort.name, reportAnalyticsType, drilldownSpecifier)}.csv`
            downloadCSV(csvString, filename)
        }

        const drillDownChartData = () => {
            const filteredData = drilldownData.filter((value: DrilldownData, index: number, array: DrilldownData[]) => {
                return value.name === `${DRILLDOWN_SECTION_ID} ${selectedDrilldownId}`
            })

            let chartDataArray

            if (filteredData.length > 0) {
                chartDataArray = filteredData[0].data.map((item) => [item[0], item[1]])
            } else {
                chartDataArray = []
            }

            const maxLength = chartDataArray.length.toString().length

            const chartData: Record<string, number> = chartDataArray.reduce((acc, [key, value]) => {
                const formattedKey = key.padStart(maxLength, '0')
                acc[formattedKey] = value
                return acc
            }, {})

            return chartData
        }

        const setViewProp = (view: 'barChart' | 'tableChart') => {
            setView(view)

            if (view === 'barChart') {
                setSelectedDrilldownId(null)
            }
        }

        return (
            <Box>
                <Box
                    display='flex'
                    justifyContent='space-between'
                    alignItems='center'
                    sx={{
                        padding: '16px',
                        backgroundColor: '#E8F0FE',
                        borderRadius: '8px',
                        boxShadow: 'inset 0 4px 8px 0 rgba(0,0,0,0.05)',
                        margin: '8px 0',
                        height: '50px'
                    }}
                >
                    <Box flexGrow={4} fontWeight={'bold'}>
                        <Trans>Patient Count by</Trans> <CriteriaTypeLabel criteriaType={type} />
                    </Box>
                    <Box height={'48px'}>
                        <ReportChartButtons
                            view={view}
                            setView={setViewProp}
                            onDownload={handleDownloadCSV}
                            isDrilledDown={selectedDrilldownId !== null}
                            disabled={disableFetching}
                        />
                    </Box>
                    {canManageReports && !renderInDashboard && (
                        <Box>
                            <Button onClick={() => setDialogOpen(true)} variant='text' startIcon={<FilterAltOutlinedIcon fontSize='small' />}>
                                <Trans>Edit Filters</Trans>
                            </Button>
                        </Box>
                    )}
                    {canManageReports && !renderInDashboard && deleteButton}
                </Box>
                <Collapse in={!!drilldownSummaryBar}>{drilldownSummaryBar}</Collapse>
                <Box
                    width={'100%'}
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    marginBottom={2}
                    sx={{ position: 'relative', backgroundColor: 'grey.100' }}
                >
                    <Box width='100%' height='100%'>
                        <Box height='100%'>
                            {view === 'barChart' && (
                                <HighchartsReact
                                    key={reportAnalyticsType}
                                    highcharts={Highcharts}
                                    containerProps={CONTAINER_PROPS}
                                    options={CHART_OPTIONS}
                                    callback={(chart) => {
                                        chartRef.current = chart
                                    }}
                                    exporting={{
                                        buttons: {
                                            contextButton: {
                                                menuItems: [
                                                    'printChart',
                                                    'downloadPDF',
                                                    'downloadPNG',
                                                    'downloadJPEG',
                                                    'downloadSVG',
                                                    'downloadCSV',
                                                    'downloadXLS'
                                                ]
                                            }
                                        }
                                    }}
                                />
                            )}
                            {view === 'tableChart' && (
                                <BasicTable
                                    chartData={selectedDrilldownId ? drillDownChartData() : chartData}
                                    valueLabel={selectedDrilldownId ? selectedDrilldownId : undefined}
                                />
                            )}
                        </Box>
                    </Box>
                </Box>
            </Box>
        )
    }, [
        report,
        analyticsLoading,
        analytics,
        refreshingData,
        type,
        view,
        selectedDrilldownId,
        disableFetching,
        canManageReports,
        renderInDashboard,
        deleteButton,
        drilldownSummaryBar,
        reportAnalyticsType,
        CHART_OPTIONS,
        chartData,
        reportsLoading,
        actions,
        drillDownObject,
        cohort.name,
        drilldownData,
        chartRef
    ])

    const renderNoFiltersNotice = useMemo<ReactNode>(() => {
        if (report || reportsLoading || analyticsLoading) {
            return null
        }

        return (
            <Box
                sx={{
                    paddingY: 4,
                    marginBottom: 3,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    borderRadius: '10px' // rounded corners for the box
                }}
            >
                <FilterAltOffOutlinedIcon
                    sx={{
                        width: '120px',
                        height: '120px',
                        color: '#002D72'
                    }}
                />
                <Box
                    sx={{
                        paddingTop: '20px',
                        paddingLeft: '20px',
                        paddingRight: '20px',
                        paddingBottom: '0px',
                        color: '#002D72',
                        fontSize: '20px',
                        opacity: reportsLoading ? '0%' : '100%'
                    }}
                >
                    <Trans>No filters are selected</Trans>
                </Box>
                {canManageReports && (
                    <Box
                        sx={{
                            paddingTop: '8px',
                            paddingBottom: '20px',
                            paddingLeft: '20px',
                            paddingRight: '20px',
                            color: '#7F7F7F',
                            fontSize: '14px',
                            opacity: reportsLoading ? '0%' : '100%'
                        }}
                    >
                        {!renderInDashboard ? (
                            <Trans>Select filters to generate a report</Trans>
                        ) : (
                            <>
                                <Trans>No</Trans> {reportAnalyticsType} <Trans> report saved for cohort.</Trans>
                            </>
                        )}
                        {renderInDashboard ? (
                            <Box display={'flex'} alignContent={'center'} alignItems={'center'} justifyContent={'center'} paddingTop={2}>
                                <Button
                                    component={Link}
                                    to={toPath(Routes.COHORTBUILD) + `/${CohortRoutes.REPORTS}/${cohort.id}/${reportAnalyticsType}`}
                                    variant='contained'
                                    color='primary'
                                    startIcon={<FilterAltOutlinedIcon fontSize='small' />}
                                >
                                    <>
                                        <Trans>Create</Trans> {reportAnalyticsType.charAt(0).toUpperCase() + reportAnalyticsType.slice(1)}{' '}
                                        <Trans>Report</Trans>
                                    </>
                                </Button>
                            </Box>
                        ) : (
                            <Box display={'flex'} alignContent={'center'} alignItems={'center'} justifyContent={'center'} paddingTop={2}>
                                <Button
                                    onClick={() => setDialogOpen(true)}
                                    variant='contained'
                                    color='primary'
                                    startIcon={<FilterAltOutlinedIcon fontSize='small' />}
                                >
                                    <Trans>Add Filters</Trans>
                                </Button>
                            </Box>
                        )}
                    </Box>
                )}
            </Box>
        )
    }, [analyticsLoading, canManageReports, cohort.id, renderInDashboard, report, reportAnalyticsType, reportsLoading])

    // Memoize the dialog component to prevent rerenders
    const RefTreeDialog = useMemo(() => createRefTreeDialog(permissions), [permissions])

    // Memoize the dialog content so it only changes when necessary dependencies change
    const criteriaDialog = useMemo(() => {
        if (!dialogOpen) {
            return null
        }

        return (
            <RefTreeDialog
                initialValue={filters}
                fieldMappers={mappers}
                onCancel={handleDialogCancel}
                onSave={handleDialogSave}
                criteriaType={type}
                saveText={<Trans>Run</Trans>}
                saveIcon={<QueryStatsOutlinedIcon />}
                onHandleRemoveQualifiers={() => {}}
                reportView={true}
            />
        )
    }, [dialogOpen, RefTreeDialog, filters, mappers, handleDialogSave, type])

    let loadingOverlay: ReactNode
    // Modify the condition to not consider dialogOpen as a loading state
    // which prevents the overlay from being constantly recreated
    if (reportsLoading || analyticsLoading || refreshingData) {
        const overlayStyles: SxProps = { alignItems: 'flex-start', paddingTop: 12 }
        loadingOverlay = (
            <Box position='absolute' top={0} left={0} right={0} bottom={0} display='flex' justifyContent='center' sx={overlayStyles}>
                <Box position='absolute' zIndex={1} top={0} left={0} right={0} bottom={0} bgcolor='grey.100' sx={{ opacity: 0.9 }} />
                <Box
                    position='relative'
                    zIndex={2}
                    display='flex'
                    flexDirection='column'
                    alignItems='center'
                    justifyContent='center'
                    gap={2}
                    p={4}
                    color='grey.600'
                >
                    <CircularProgress color='inherit' />
                    <Typography>
                        <strong>
                            <Trans>We&apos;re gathering your data. Queries typically finish in less than 30 seconds.</Trans>
                        </strong>
                    </Typography>
                </Box>
            </Box>
        )
    } else {
        loadingOverlay = null
    }

    return (
        <Box position='relative' minHeight={disableFetching ? '' : window.innerHeight} maxHeight={'100%'} marginBottom={0}>
            {renderNoFiltersNotice}
            {renderReport}
            {dialogOpen && criteriaDialog}
            {loadingOverlay}
        </Box>
    )
}
