export enum Routes {
    HOME = 'home',
    ADMIN = 'admin',
    SETTINGS = 'settings',
    INSIGHTS_LIBRARY = 'insights-library',
    COHORTBUILD = 'cohort-builder',
    BACKOFFICE = 'back-office',
    PLATFORM_SETTINGS = 'platform-settings',
    PROFILE = 'profile',
    NOTFOUND = '404',
    ACCESSDENIED = '403',
    USERACCOUNTS = 'useraccounts',
    ORGANIZATIONS = 'organizations',
    ROLES = 'roles',
    RWA_CONDITIONS = 'rwa-conditions',
    ASSISTANT = 'assistant',
    INGEST = 'ingest'
}
