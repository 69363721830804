import { Trans } from '@lingui/macro'
import { PlatformRoute, toRoutingConfigObject } from '@om1/cohort-module'
import { createRWAConditionsPage, createRWAInsightsReportsPage, createAnalyticsBuildPage, PlatformSettingRoutes } from '@om1/platform-settings-module'
import { FilterTabBar, FilterTabBarButton, FilterTabBarDivider } from '@om1/platform-ui-kit/src/components/FilterTabBar'
import { ContentContainerStyle, ContentPadding } from '@om1/platform-ui-kit/src/components/Layout'
import { PlatformPermissions } from '@om1/platform-utils'
import React from 'react'
import { Link, Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom'
import { PlatformState } from '../../platform-state'
import { AccessDeniedPage } from '../AccessDeniedPage'
import { createPermissionProtectedRouteComponent } from '../PermissionProtectedRoute'

const RWAConditionsPage = createRWAConditionsPage<PlatformState>()
const RWAInsightsReportsPage = createRWAInsightsReportsPage<PlatformState>()
const AnalyticsBuildPage = createAnalyticsBuildPage<PlatformState>()

export const PlatformSettingsPage: React.FunctionComponent = () => {
    const match = useRouteMatch()
    const location = useLocation()

    if (!match) {
        return null
    }
    const { path: matchPathValue, url: matchUrlValue } = match

    const routes: PlatformRoute[] = [
        {
            route: PlatformSettingRoutes.RWA_CONDITIONS,
            component: RWAConditionsPage,
            permissions: [PlatformPermissions.ACCESS_RWA_INSIGHTS_CONDITIONS],
            name: <Trans>RWA Conditions</Trans>
        },
        {
            route: PlatformSettingRoutes.RWA_INSIGHTS_REPORTS,
            component: RWAInsightsReportsPage,
            permissions: [PlatformPermissions.ACCESS_RWA_INSIGHTS_REPORTS],
            name: <Trans>RWA Insights Reports</Trans>
        },
        {
            route: PlatformSettingRoutes.ANALYTICS_BUILD,
            component: AnalyticsBuildPage,
            permissions: [],
            name: <Trans>Analytics Build</Trans>
        }
    ]

    const PermissionProtectedRoute = createPermissionProtectedRouteComponent<PlatformState>()

    const routingConfigObject = toRoutingConfigObject(routes, matchPathValue, matchUrlValue)

    return (
        <ContentContainerStyle>
            <FilterTabBar>
                {routes.map((route, index) => (
                    <React.Fragment key={route.route}>
                        <Link to={`${matchUrlValue}/${route.route}`} style={{ textDecoration: 'none' }}>
                            <FilterTabBarButton active={location.pathname === `${matchUrlValue}/${route.route}`}>{route.name}</FilterTabBarButton>
                        </Link>
                        {index < routes.length - 1 && <FilterTabBarDivider />}
                    </React.Fragment>
                ))}
            </FilterTabBar>
            <ContentPadding>
                <Switch>
                    {routingConfigObject.map((route) => (
                        <Route
                            key={route.route}
                            path={route.matchRoute.path}
                            exact
                            render={(props) => (
                                <PermissionProtectedRoute
                                    permissions={route.permissions}
                                    accessDeniedComponent={<AccessDeniedPage />}
                                    protectedChild={React.createElement(route.component)}
                                />
                            )}
                        />
                    ))}
                    <Redirect from={matchPathValue} to={`${matchUrlValue}/${PlatformSettingRoutes.RWA_CONDITIONS}`} />
                </Switch>
            </ContentPadding>
        </ContentContainerStyle>
    )
}
