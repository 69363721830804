/* eslint-disable */
import { Trans } from '@lingui/macro'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import AssessmentIcon from '@mui/icons-material/Assessment'
import HomeIcon from '@mui/icons-material/Home'
import InputIcon from '@mui/icons-material/Input'
import SettingsIcon from '@mui/icons-material/Settings'
import ViewInArIcon from '@mui/icons-material/ViewInAr'
import { Box, styled } from '@mui/material'
import { CohortRoutes, PlatformRoute } from '@om1/cohort-module'
import { falconApiActions, FalconApiState } from '@om1/falcon-api'
import { InsightsLibraryPage } from '@om1/insights-library-module'
import { IngestPage } from '@om1/platform-assistant-ui/src/layouts/IngestPage'
import { useUserPermissions } from '@om1/platform-authentication'
import { PlatformConfigState } from '@om1/platform-config/state'
import { notificationActions } from '@om1/platform-notifications'
import { Routes, toPath } from '@om1/platform-routing'
import { createFooterComponent } from '@om1/platform-ui-kit/src/components/Footer'
import { MainContainer, PageContainer } from '@om1/platform-ui-kit/src/components/Layout'
import { ResponsiveSideBar } from '@om1/platform-ui-kit/src/components/sidebar/ResponsiveSideBar'
import { connectRedux, FrameworkComponentProps, PlatformPermissions } from '@om1/platform-utils'
import React, { ComponentType, ReactElement } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { PlatformState } from '../platform-state'
import CohortsListNavIcon from '../shared/assets/CE_wht_icon_40x40_2x.png'
import { AccessDeniedPage } from './AccessDeniedPage'
import { BackOfficePage } from './BackOfficePage'
import { CohortPage } from './CohortPage/CohortPage'
import { HomePage } from './HomePage'
import logo from './Logo'
import { NotFoundPage } from './NotFoundPage/NotFoundPage'
import { createPermissionProtectedRouteComponent } from './PermissionProtectedRoute'
import { PlatformSettingsPage } from './PlatformSettingsPage'
import { SettingsPage } from './SettingsPage/SettingsPage'
const NavIcon = styled('img')({
    width: '24px',
    height: '24px',
    cursor: 'pointer'
})

const Footer = createFooterComponent<PlatformState>()

type LandingPageComponentProps = FrameworkComponentProps<
    FalconApiState & PlatformConfigState,
    typeof notificationActions & typeof falconApiActions,
    {}
>

const routeConfig: PlatformRoute[] = [
    {
        route: Routes.HOME,
        component: HomePage,
        permissions: [PlatformPermissions.ADMIN_EXPLORER],
        icon: <HomeIcon sx={{ color: '#FFFFFF', width: 24, height: 24 }} />,
        name: <Trans>Home</Trans>
    },
    {
        route: Routes.SETTINGS,
        component: SettingsPage,
        permissions: [PlatformPermissions.ADMIN_EXPLORER],
        icon: <SettingsIcon sx={{ color: '#FFFFFF' }} />
    },
    {
        route: Routes.COHORTBUILD,
        component: CohortPage,
        permissions: [PlatformPermissions.ACCESS_COHORTS],
        icon: <NavIcon src={CohortsListNavIcon} />,
        name: <Trans>Cohort Explorer</Trans>
    },
    {
        route: Routes.INSIGHTS_LIBRARY,
        component: InsightsLibraryPage,
        permissions: [PlatformPermissions.ACCESS_INSIGHTS_LIBRARY],
        icon: <AssessmentIcon sx={{ color: '#FFFFFF' }} />,
        name: <Trans>Insights Library</Trans>
    },
    {
        route: Routes.ACCESSDENIED,
        component: AccessDeniedPage,
        permissions: []
    },
    {
        route: Routes.NOTFOUND,
        component: NotFoundPage,
        permissions: []
    },
    {
        route: Routes.BACKOFFICE,
        component: BackOfficePage,
        permissions: [PlatformPermissions.ACCESS_DELIVERY, PlatformPermissions.INDEX_COHORTS],
        icon: <ViewInArIcon sx={{ color: '#FFFFFF', width: 24, height: 24 }} />,
        name: <Trans>Back Office</Trans>
    },
    {
        route: Routes.PLATFORM_SETTINGS,
        component: PlatformSettingsPage,
        permissions: [PlatformPermissions.ACCESS_PLATFORM_SETTINGS],
        icon: <AdminPanelSettingsIcon sx={{ color: '#FFFFFF', width: 24, height: 24 }} />,
        name: <Trans>Platform Settings</Trans>
    },
    {
        route: Routes.INGEST,
        component: IngestPage,
        permissions: [PlatformPermissions.ACCESS_INGEST],
        icon: <InputIcon sx={{ color: '#FFFFFF', width: 24, height: 24 }} />,
        name: <Trans>Ingest</Trans>
    }
]

const LandingPageComponent: React.FunctionComponent<LandingPageComponentProps> = ({ actions, state }) => {
    const PermissionProtectedRoute = createPermissionProtectedRouteComponent<PlatformState>()

    let routes = routeConfig.map((value: PlatformRoute) => {
        const { route, component, permissions } = value
        return (
            <Route key={route} path={toPath(route)}>
                <PermissionProtectedRoute
                    accessDeniedComponent={<AccessDeniedPage />}
                    protectedChild={React.createElement(component)}
                    permissions={permissions}
                />
            </Route>
        )
    })

    const sideBarItems = routeConfig.filter((value: PlatformRoute) => {
        const { name, icon } = value
        const { permissions } = useUserPermissions()
        return (
            name !== undefined && icon !== undefined && value.permissions.every((required_permission) => permissions?.includes(required_permission))
        )
    }) as {
        route: string
        component: ComponentType<any>
        name: ReactElement<any, any>
        icon: ReactElement<any, any>
    }[]

    return (
        <MainContainer>
            <>
                <ResponsiveSideBar
                    logo={logo()}
                    sideBarItems={sideBarItems}
                    appSemanticVersion={state.semanticVersion}
                    jenkinsBuildNumber={state.buildNumber}
                    environmentTag={state.environmentTag}
                    falconApiVersion={state.version}
                    falconAlembicVersion={state.alembicVersion}
                    showHelpDialog={actions.showHelpDialog}
                >
                    <PageContainer>
                        <Box
                            style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                overscrollBehavior: 'contain'
                            }}
                        >
                            <Switch>
                                {routes}
                                <Redirect
                                    key={'emptyRedirect'}
                                    from={'/'}
                                    exact
                                    to={toPath(Routes.COHORTBUILD) + '/' + CohortRoutes.DASHBOARD + '/'}
                                />
                                ,
                                <Redirect key={'notFoundRedirect'} from={'*'} to={toPath(Routes.NOTFOUND)} />
                            </Switch>
                        </Box>
                    </PageContainer>
                </ResponsiveSideBar>
                <Footer />
            </>
        </MainContainer>
    )
}

export function createLandingPageComponent<TState extends { falconApi: FalconApiState; platformConfig: PlatformConfigState }>() {
    return connectRedux(
        LandingPageComponent,
        (state: TState) => {
            return { ...state.falconApi, ...state.platformConfig }
        },
        { ...notificationActions, ...falconApiActions }
    )
}

export type LandingPage = ReturnType<typeof createLandingPageComponent>
