/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AssistantAIMessage_Output } from '../models/AssistantAIMessage_Output'
import type { AssistantHumanMessage } from '../models/AssistantHumanMessage'
import type { AssistantMessagesAdapter } from '../models/AssistantMessagesAdapter'
import type { AssistantSystemMessage } from '../models/AssistantSystemMessage'
import type { AssistantToolMessage } from '../models/AssistantToolMessage'
import type { ChatModel } from '../models/ChatModel'
import type { IngestConversationCreateDTO } from '../models/IngestConversationCreateDTO'
import type { IngestConversationDetailDTO } from '../models/IngestConversationDetailDTO'
import type { IngestConversationDTO } from '../models/IngestConversationDTO'
import type { IngestConversationPatchDTO } from '../models/IngestConversationPatchDTO'
import type { PaginatedDTO_IngestConversationDTO_ } from '../models/PaginatedDTO_IngestConversationDTO_'
import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'
export class IngestConversationsService {
    /**
     * Index
     * List all ingest conversations.
     * @returns PaginatedDTO_IngestConversationDTO_ Successful Response
     * @throws ApiError
     */
    public static indexIngestConversationsGet({
        page = 1,
        limit = 10
    }: {
        /**
         * Page number for pagination, starting at 1
         */
        page?: number
        /**
         * Number of items per page, maximum 1000
         */
        limit?: number
    }): CancelablePromise<PaginatedDTO_IngestConversationDTO_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ingest/conversations',
            query: {
                page: page,
                limit: limit
            },
            errors: {
                422: `Validation Error`
            }
        })
    }
    /**
     * Create
     * Create a new ingest conversation.
     * @returns IngestConversationDTO Successful Response
     * @throws ApiError
     */
    public static createIngestConversationsPost({
        requestBody
    }: {
        requestBody: IngestConversationCreateDTO
    }): CancelablePromise<IngestConversationDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ingest/conversations',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`
            }
        })
    }
    /**
     * Get
     * Get a specific ingest conversation.
     * @returns IngestConversationDetailDTO Successful Response
     * @throws ApiError
     */
    public static getIngestConversationsIdGet({
        id,
        model = 'o3-mini'
    }: {
        id: string
        model?: ChatModel
    }): CancelablePromise<IngestConversationDetailDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ingest/conversations/{id}',
            path: {
                id: id
            },
            query: {
                model: model
            },
            errors: {
                422: `Validation Error`
            }
        })
    }
    /**
     * Patch
     * Update a specific ingest conversation.
     * @returns IngestConversationDTO Successful Response
     * @throws ApiError
     */
    public static patchIngestConversationsIdPatch({
        id,
        requestBody
    }: {
        id: string
        requestBody: IngestConversationPatchDTO
    }): CancelablePromise<IngestConversationDTO> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/ingest/conversations/{id}',
            path: {
                id: id
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`
            }
        })
    }
    /**
     * Delete
     * Delete a specific ingest conversation.
     * @returns string Successful Response
     * @throws ApiError
     */
    public static deleteIngestConversationsIdDelete({ id }: { id: string }): CancelablePromise<Record<string, string>> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/ingest/conversations/{id}',
            path: {
                id: id
            },
            errors: {
                422: `Validation Error`
            }
        })
    }
    /**
     * Ingest Invoke
     * Send messages to the ingest agent.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static ingestInvokeIngestConversationsIdInvokePost({
        id,
        requestBody,
        model = 'o3-mini'
    }: {
        id: string
        requestBody: AssistantMessagesAdapter
        model?: ChatModel
    }): CancelablePromise<Array<AssistantAIMessage_Output | AssistantHumanMessage | AssistantToolMessage | AssistantSystemMessage>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ingest/conversations/{id}/invoke',
            path: {
                id: id
            },
            query: {
                model: model
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`
            }
        })
    }
}
