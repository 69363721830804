import { Trans } from '@lingui/macro'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import { Box, Button, Dialog, DialogContent, DialogContentText } from '@mui/material'
import { GridRowId } from '@mui/x-data-grid'
import React, { Dispatch, SetStateAction } from 'react'
import { CohortListItem } from '../../state'
import { cohortDeleteActions } from '../../state/delete/cohort-delete-actions'

export interface DeleteCohortDialogProps {
    id: GridRowId
    actions: Pick<typeof cohortDeleteActions, 'cohortDelete'>
    onCancel: () => void
    searchValue: string | undefined
    page: number | undefined
    selectedCohort?: CohortListItem | undefined
    setCohortId: Dispatch<SetStateAction<string | undefined>>
}

/**
 * A dialog used to confirm and trigger the deletion of a cohort.
 */
export const DeleteCohortDialog: React.FunctionComponent<DeleteCohortDialogProps> = (props) => {
    const closeModal = () => {
        props.onCancel()
    }
    const handleConfirmNavigationClick = () => {
        if (props?.selectedCohort?.id === (props.id as string)) {
            props.setCohortId('-1')
        }
        props.actions.cohortDelete({ id: props.id as string, refreshList: true, search: props.searchValue, page: props.page })
        props.onCancel()
    }
    return (
        <Dialog open onClose={closeModal} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
            <DialogContent
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-around',
                    height: '375px',
                    width: '500px',
                    backgroundColor: '#FFFFFF',
                    boxShadow: '15px 10px 56px 0 rgba(0,0,0,0.17)'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >
                    <DeleteIcon
                        sx={{
                            width: '120px',
                            height: '120px',
                            color: '#002D72'
                        }}
                    />

                    <DialogContentText
                        id='alert-dialog-description'
                        sx={{
                            padding: '20px',
                            color: '#002D72',

                            fontSize: '20px',
                            letterSpacing: '0',
                            lineHeight: '30px',
                            textAlign: 'center'
                        }}
                    >
                        <Trans>Delete Cohort</Trans>
                    </DialogContentText>
                    <DialogContentText
                        id='alert-dialog-description'
                        sx={{ padding: '20px', color: '#7F7F7F', fontSize: '14px', letterSpacing: '0', lineHeight: '20px' }}
                    >
                        <Trans>Are you sure you want to delete this cohort?</Trans>
                    </DialogContentText>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end'
                    }}
                >
                    <Button
                        sx={{
                            textTransform: 'none',

                            fontSize: '16px',
                            marginLeft: '10px',
                            marginRight: '10px',
                            padding: '10px 20px 10px 20px'
                        }}
                        onClick={closeModal}
                    >
                        <Trans>Cancel</Trans>
                    </Button>
                    <Button
                        variant='contained'
                        sx={{
                            textTransform: 'none',

                            fontSize: '16px',
                            letterSpacing: '0',
                            marginLeft: '10px',
                            marginRight: '10px',
                            padding: '10px 20px 10px 20px',
                            color: '#FFFFFF'
                        }}
                        onClick={handleConfirmNavigationClick}
                        autoFocus
                    >
                        <Trans>Yes, Delete</Trans>
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    )
}
