import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { IngestConversationsService } from '../client'

export const QUERY_KEYS = {
    ingestConversationDetail: 'ingestConversationDetail'
} as const

export type IngestConversationTokenUsage = {
    inputTokens: number
    outputTokens: number
    totalTokens: number
}

export function useIngestConversation(params: Parameters<typeof IngestConversationsService.getIngestConversationsIdGet>[0]) {
    const memoizedParams = useMemo(() => params, [params])

    const {
        data: ingestConversationDetail,
        isLoading,
        refetch
    } = useQuery({
        queryKey: [QUERY_KEYS.ingestConversationDetail, memoizedParams.id],
        queryFn: () =>
            IngestConversationsService.getIngestConversationsIdGet({
                id: memoizedParams.id
            })
    })

    const ingestConversationTokenUsage: IngestConversationTokenUsage = useMemo(() => {
        return (ingestConversationDetail?.messages || []).reduce(
            (acc, message) => {
                if ('usage_metadata' in message && message.usage_metadata) {
                    return {
                        inputTokens: acc.inputTokens + (message.usage_metadata.input_tokens || 0),
                        outputTokens: acc.outputTokens + (message.usage_metadata.output_tokens || 0),
                        totalTokens: acc.totalTokens + (message.usage_metadata.total_tokens || 0)
                    }
                }
                return acc
            },
            { inputTokens: 0, outputTokens: 0, totalTokens: 0 }
        )
    }, [ingestConversationDetail])

    return {
        isLoading,
        ingestConversationDetail,
        refetchIngestConversationDetail: refetch,
        ingestConversationTokenUsage
    }
}
