import { Routes } from '@om1/platform-routing'
import { Redirect, Route, Switch } from 'react-router-dom'
import { IngestConversationList } from '../views/IngestConversationList'
import IngestView from '../views/IngestView'

export function IngestPage() {
    return (
        <div style={{ overflow: 'hidden' }}>
            <Switch>
                <Route path={`/${Routes.INGEST}/conversation/:conversationId`} component={IngestView} />
                <Route path={`/${Routes.INGEST}`} component={IngestConversationList} />
                <Redirect to={`/${Routes.INGEST}`} />
            </Switch>
        </div>
    )
}
