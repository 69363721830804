/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { t } from '@lingui/macro'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar, TextField, Typography } from '@mui/material'
import { styled } from '@mui/system'
import { ChatModel } from '@om1/falcon-api/codegen/client/models/ChatModel'
import { IngestConversationPatchDTO } from '@om1/falcon-api/codegen/client/models/IngestConversationPatchDTO'
import { useIngestConversation } from '@om1/falcon-api/codegen/hooks/useIngestConversation'
import { useUpdateConversation } from '@om1/falcon-api/codegen/hooks/useIngestConversations'
import { ArrowLeft, Pencil, Share2 } from 'lucide-react'
import { useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

const HeaderContainer = styled('div')({
    borderBottom: '1px solid #e2e8f0',
    padding: '0.75rem 1rem',
    position: 'sticky',
    top: 0,
    zIndex: 10,
    backdropFilter: 'blur(10px)',
    backgroundColor: 'rgba(255, 255, 255, 0.98)',
    width: '100%',
    flexShrink: 0
})

const HeaderContent = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: '1400px',
    margin: '0 auto',
    gap: '1rem'
})

const LeftSection = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    flex: 1,
    minWidth: 0
})

const BackButton = styled('button')({
    padding: '0.5rem',
    borderRadius: '0.75rem',
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'background-color 0.2s',
    '&:hover': {
        backgroundColor: '#f3f4f6'
    }
})

const BetaLabel = styled('span')({
    backgroundColor: '#012D72',
    color: '#fff',
    borderRadius: '6px',
    fontSize: '0.75rem',
    padding: '0.25rem 0.5rem',
    fontWeight: 500,
    letterSpacing: '0.025em'
})

const ActionButton = styled('button')({
    padding: '0.5rem',
    borderRadius: '0.75rem',
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    color: '#64748b',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all 0.2s',
    '&:hover': {
        backgroundColor: '#f3f4f6',
        color: '#0f172a'
    }
})

const TitleContainer = styled('div')({
    flex: 1,
    minWidth: 0,
    marginTop: '-0.25rem'
})

export const IngestHeader = (props: { conversationId: string }) => {
    const history = useHistory()
    const location = useLocation()
    const conversationParams = useMemo(() => ({ id: props.conversationId, model: 'gpt-4o' as ChatModel }), [props.conversationId])
    const { ingestConversationDetail } = useIngestConversation(conversationParams)
    const updateConversation = useUpdateConversation()
    const [editDialogOpen, setEditDialogOpen] = useState(false)
    const [newTitle, setNewTitle] = useState('')
    const [showCopiedSnackbar, setShowCopiedSnackbar] = useState(false)

    const handleEditClick = () => {
        setNewTitle(ingestConversationDetail?.title || '')
        setEditDialogOpen(true)
    }

    const handleShare = async () => {
        try {
            const url = window.location.origin + location.pathname
            await navigator.clipboard.writeText(url)
            setShowCopiedSnackbar(true)
        } catch (error) {
            console.error('Failed to copy URL:', error)
        }
    }

    const handleEditSave = async () => {
        if (newTitle.trim()) {
            try {
                await updateConversation.mutateAsync({
                    id: props.conversationId,
                    requestBody: {
                        title: newTitle.trim()
                    } as IngestConversationPatchDTO
                })
                setEditDialogOpen(false)
                setNewTitle('')
            } catch (error) {
                console.error('Error updating conversation:', error)
            }
        }
    }

    return (
        <HeaderContainer>
            <HeaderContent>
                <LeftSection>
                    <BackButton onClick={() => history.goBack()}>
                        <ArrowLeft size={20} />
                    </BackButton>
                    <AutoAwesomeIcon sx={{ color: '#012D72', width: 20, height: 20 }} />
                    <BetaLabel>Beta</BetaLabel>
                    <TitleContainer>
                        <Typography
                            sx={{
                                alignSelf: 'flex-start',
                                margin: 0,
                                fontSize: '1rem',
                                fontWeight: 500,
                                color: '#0f172a'
                            }}
                        >
                            {ingestConversationDetail?.title || ingestConversationDetail?.id}
                        </Typography>
                    </TitleContainer>
                </LeftSection>
                <div style={{ display: 'flex', gap: '0.5rem' }}>
                    <ActionButton title={t`Share conversation`} onClick={handleShare}>
                        <Share2 size={18} />
                    </ActionButton>
                    <ActionButton title={t`Edit title`} onClick={handleEditClick}>
                        <Pencil size={18} />
                    </ActionButton>
                </div>
            </HeaderContent>

            <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)} maxWidth='sm' fullWidth>
                <DialogTitle>{t`Edit Conversation Title`}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin='dense'
                        label={t`Title`}
                        type='text'
                        fullWidth
                        value={newTitle}
                        onChange={(e) => setNewTitle(e.target.value)}
                        variant='outlined'
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setEditDialogOpen(false)} color='inherit'>
                        {t`Cancel`}
                    </Button>
                    <Button onClick={handleEditSave} variant='contained' color='primary' disabled={!newTitle.trim() || updateConversation.isLoading}>
                        {t`Save`}
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={showCopiedSnackbar}
                autoHideDuration={2000}
                onClose={() => setShowCopiedSnackbar(false)}
                message={t`URL copied to clipboard`}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                sx={{
                    '& .MuiSnackbarContent-root': {
                        backgroundColor: '#0A84FF',
                        color: 'white',
                        fontWeight: 500,
                        top: '100'
                    }
                }}
            />
        </HeaderContainer>
    )
}
