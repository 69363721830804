import { TrackingPlanEvents, track, trackingEvent } from '@om1/platform-tracking'
import { ActionsUnion, createAction } from '@om1/platform-utils'

export enum CohortDeleteActionTypes {
    SET_IS_LOADING = '@@cohorts/delete/loading-set',
    COHORT_DELETE = '@@cohorts/delete'
}

export const cohortDeleteActions = {
    loadingSet: (payload: { loading: boolean }) => createAction(CohortDeleteActionTypes.SET_IS_LOADING, payload),
    cohortDelete: (payload: { id: string; refreshList: boolean; search: string | undefined; page: number | undefined }) =>
        track(createAction(CohortDeleteActionTypes.COHORT_DELETE, payload), trackingEvent(TrackingPlanEvents.COHORT_DELETE, { cohortId: payload.id }))
}

export type CohortDeleteActions = ActionsUnion<typeof cohortDeleteActions>
