/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { CircularProgress } from '@mui/material'
import { styled } from '@mui/system'
import { AssistantAIMessage_Output, AssistantHumanMessage, AssistantToolMessage } from '@om1/falcon-api'
import { OpenAPI } from '@om1/falcon-api/codegen/client/core/OpenAPI'
import { ChatModel } from '@om1/falcon-api/codegen/client/models/ChatModel'
import { AssistantConversationTokenUsage } from '@om1/falcon-api/codegen/hooks/useAssistantConversation'
import { useIngestConversation } from '@om1/falcon-api/codegen/hooks/useIngestConversation'
import { falconApiConfig } from '@om1/falcon-api/falcon-api-config'
import { Bot, SquareDashed, SquareDashedMousePointer, User, Wrench } from 'lucide-react'
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { NodeTypes } from '../components/query/QueryBlock'
import { MessageForm } from './MessageForm'
import { MessageTokenUsage } from './MessageTokenUsage'
import { RenderedMessage } from './RenderedMessage'
import { selectedFilterKey } from './SystemMessages'

const ConversationContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#F5F7FA',
    overflow: 'hidden',
    maxHeight: '80vh',
    minHeight: '80vh'
})

const MessagesContainer = styled('div')({
    flex: 1,
    overflowY: 'auto',
    padding: '1rem',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
        display: 'none'
    }
})

const MessagesList = styled('div')({
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    maxWidth: '800px',
    paddingBottom: '1rem'
})

const MessageAvatar = styled('div')<{ type: 'human' | 'ai' | 'tool' | 'system' }>(({ type }) => ({
    flexShrink: 0,
    width: '2rem',
    height: '2rem',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: type === 'human' ? '#ebf8ff' : '#F5F5F5',
    border: '1px solid',
    borderColor: type === 'human' ? '#bde3ff' : '#e2e8f0'
}))

const MessageBubble = styled('div')<{ type: 'human' | 'ai' | 'tool' | 'system' }>(({ type }) => ({
    flexGrow: 1,
    padding: '0.875rem 1rem',
    borderRadius: '1rem',
    backgroundColor: type === 'human' ? '#FFFFFF' : '#F8FAFC',
    border: '1px solid',
    borderColor: type === 'human' ? '#3182CE' : '#E2E8F0',
    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)'
}))

const MessageText = styled('p')({
    color: '#2d3748',
    whiteSpace: 'pre-wrap',
    margin: 0,
    fontSize: '0.9375rem',
    lineHeight: 1.5
})

const InputContainer = styled('div')({
    borderTop: '1px solid #e2e8f0',
    padding: '1rem',
    position: 'sticky',
    bottom: 0,
    width: '100%',
    zIndex: 10,
    backdropFilter: 'blur(10px)',
    backgroundColor: 'rgba(255, 255, 255, 0.98)'
})

const InputWrapper = styled('div')({
    maxWidth: '800px',
    margin: '0 auto'
})

const LoadingIndicator = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    color: '#718096',
    fontStyle: 'italic',
    padding: '0.5rem 0.75rem',
    borderRadius: '0.75rem',
    backgroundColor: '#F8FAFC',
    border: '1px solid #E2E8F0',
    width: 'fit-content'
})

const MessageList = memo(
    ({
        messages,
        selectedBlock
    }: {
        messages: (AssistantAIMessage_Output | AssistantHumanMessage | AssistantToolMessage)[]
        selectedBlock: NodeTypes | undefined
    }) => {
        const toolCallsAreComplete = useMemo(() => {
            const toolCallStatuses = new Map<string, { complete: boolean; status?: string; error?: string }>()

            messages.forEach((message) => {
                if (message.additional_kwargs?.tool_calls) {
                    message.additional_kwargs.tool_calls.forEach((toolCall) => {
                        if (!toolCallStatuses.has(toolCall.id)) {
                            toolCallStatuses.set(toolCall.id, { complete: false })
                        }
                    })
                } else if (message.type === 'tool' && (message as AssistantToolMessage).tool_call_id) {
                    toolCallStatuses.set((message as AssistantToolMessage).tool_call_id, {
                        complete: true,
                        status: (message as AssistantToolMessage).status,
                        error: (message as AssistantToolMessage).content
                    })
                }
            })
            return toolCallStatuses
        }, [messages])

        return (
            <>
                {messages.map((msg, index) => {
                    const type =
                        msg['type'] === 'ai'
                            ? msg['additional_kwargs']?.tool_calls && msg['additional_kwargs'].tool_calls.length > 0
                                ? 'tool'
                                : 'ai'
                            : msg['type']
                    if (type === 'tool' && msg['tool_call_id'] !== undefined) {
                        return null
                    }

                    const isHuman = type === 'human'
                    const isSystem = type === 'system'

                    return (
                        <div
                            key={index}
                            style={{
                                display: 'flex',
                                alignItems: type === 'tool' ? 'center' : 'flex-start',
                                flexDirection: isHuman || isSystem ? 'row-reverse' : 'row',
                                gap: '0.75rem',
                                maxWidth: '800px'
                            }}
                        >
                            <MessageAvatar type={type as 'human' | 'ai' | 'tool' | 'system'}>
                                {type === 'human' ? (
                                    <User size={16} color='#3182CE' />
                                ) : type === 'tool' ? (
                                    <Wrench size={16} color='#718096' />
                                ) : type === 'system' ? (
                                    msg.content.includes(selectedFilterKey) ? (
                                        <SquareDashedMousePointer size={16} color='#0000FF' />
                                    ) : (
                                        <SquareDashed size={16} color='#FF1512' />
                                    )
                                ) : (
                                    <Bot size={16} color='#718096' />
                                )}
                            </MessageAvatar>
                            <MessageBubble type={type as 'human' | 'ai' | 'tool' | 'system'}>
                                <MessageText>
                                    <RenderedMessage message={msg} toolCallsAreComplete={toolCallsAreComplete} selectedBlock={selectedBlock} />
                                </MessageText>
                                {type === 'ai' && 'usage_metadata' in msg && msg.usage_metadata && (
                                    <div style={{ marginTop: '0.5rem' }}>
                                        <MessageTokenUsage message={msg} />
                                    </div>
                                )}
                            </MessageBubble>
                        </div>
                    )
                })}
            </>
        )
    }
)

MessageList.displayName = 'MessageList'

export const IngestConversation = (props: { conversationId: string }) => {
    const [model, setModel] = useState<ChatModel>('gpt-4o')
    const [message, setMessage] = useState('')
    const conversationParams = useMemo(() => ({ id: props.conversationId, model: 'gpt-4o' as ChatModel }), [props.conversationId])

    const { ingestConversationDetail, refetchIngestConversationDetail, ingestConversationTokenUsage } = useIngestConversation(conversationParams)
    const [messages, setMessages] = useState<Array<AssistantAIMessage_Output | AssistantHumanMessage | AssistantToolMessage>>(
        ingestConversationDetail?.messages || []
    )
    const [tokenUsage, setTokenUsage] = useState<AssistantConversationTokenUsage>(ingestConversationTokenUsage)
    const messagesEndRef = useRef<HTMLDivElement | null>(null)
    const [assistantIsResponding, setAssistantIsResponding] = useState<boolean>(false)
    const websocketUrl = `${falconApiConfig.falconApiUrl?.replace(new RegExp(/^http/), 'ws')}/ingest/conversations/${
        props.conversationId
    }?model=${model}`
    const wsRef = useRef<WebSocket | null>(null)

    const refetchRef = useRef(refetchIngestConversationDetail)

    useEffect(() => {
        refetchRef.current = refetchIngestConversationDetail
    }, [refetchIngestConversationDetail])

    const handleWebSocketMessage = useCallback((event: MessageEvent) => {
        const newMessage = JSON.parse(event.data)
        if (newMessage?.type === 'ai') {
            if (!(newMessage?.tool_calls?.length > 0)) {
                setAssistantIsResponding(false)
            }
            if (newMessage?.usage_metadata) {
                setTokenUsage((prev) => ({
                    inputTokens: prev.inputTokens + newMessage.usage_metadata.input_tokens,
                    outputTokens: prev.outputTokens + newMessage.usage_metadata.output_tokens,
                    totalTokens: prev.totalTokens + newMessage.usage_metadata.total_tokens
                }))
            }
        } else if (newMessage?.type === 'tool') {
            const status = newMessage['status']
            const name = newMessage['name']
            if (status) {
                switch (name) {
                    case 'update_conversation_title':
                        refetchRef.current()
                        break
                    default:
                        break
                }
            }
        }
        setMessages((prevMessages) => [...prevMessages, newMessage])
    }, [])

    useEffect(() => {
        const ws = new WebSocket(websocketUrl)
        wsRef.current = ws

        ws.onopen = () => {
            ws.send(JSON.stringify({ token: String(OpenAPI.TOKEN) }))
        }

        ws.onmessage = handleWebSocketMessage

        ws.onerror = (error) => {
            console.error('WebSocket error:', error)
        }

        ws.onclose = () => {
            console.log('Assistant Conversation websocket connection closed')
        }

        return () => {
            ws.close()
        }
    }, [websocketUrl, handleWebSocketMessage])

    const sendMessage = (content: string) => {
        if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
            wsRef.current.send(JSON.stringify({ type: 'human', content }))
            setMessages((prevMessages) => [...prevMessages, { type: 'human', content }])
            setAssistantIsResponding(true)
        }
    }

    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
        }
    }, [messages])

    return (
        <ConversationContainer>
            <MessagesContainer>
                <MessagesList>
                    <MessageList messages={messages} selectedBlock={undefined} />
                    <div ref={messagesEndRef} />
                    {assistantIsResponding && (
                        <LoadingIndicator>
                            <CircularProgress color='inherit' size='1em' />
                            <span>Assistant is responding...</span>
                        </LoadingIndicator>
                    )}
                </MessagesList>
            </MessagesContainer>

            <InputContainer>
                <InputWrapper>
                    <MessageForm
                        sendMessage={sendMessage}
                        model={model}
                        setModel={setModel}
                        message={message}
                        setMessage={setMessage}
                        messageCount={messages.length}
                        conversationTokenUsage={tokenUsage}
                        suggestions={['What tool calls are available?']}
                    />
                </InputWrapper>
            </InputContainer>
        </ConversationContainer>
    )
}
