/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { Trans } from '@lingui/macro'
import { Tooltip } from '@mui/material'
import { ChatModel } from '@om1/falcon-api'
import { AssistantConversationTokenUsage } from '@om1/falcon-api/codegen/hooks/useAssistantConversation'
import { IngestConversationTokenUsage } from '@om1/falcon-api/codegen/hooks/useIngestConversation'
import { useUserPermissions } from '@om1/platform-authentication'
import { PlatformPermissions } from '@om1/platform-utils'
import { Send } from 'lucide-react'
import React, { memo, useRef, useState } from 'react'
import { CriteriaTypeIcon } from '../components/query/filters/FilterHeader'
import { capitalizeFirstLetter } from '../components/query/nodes/Or'
import { FilterTypes } from '../components/query/QueryBlock'
import { SelectedBlockProps } from './ChatView'
import { MessageInput } from './MessageInput'

export enum ChatModels {
    GPT_4o = 'gpt-4o',
    GPT_4o_mini = 'gpt-4o-mini',
    O1 = 'o1',
    O3_MINI = 'o3-mini'
}

export type MessageFormProps = {
    sendMessage: (content: string) => void
    model: ChatModel
    setModel: (model: ChatModel) => void
    message: string
    setMessage: (message: string) => void
    messageCount: number
    conversationTokenUsage: AssistantConversationTokenUsage | IngestConversationTokenUsage
    suggestions: string[]
} & SelectedBlockProps

// Utility function to capitalize each word in a string
const capitalizeWords = (text: string): string => {
    return text
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
}

export const MessageForm = memo(
    ({
        sendMessage,
        model,
        setModel,
        message,
        setMessage,
        selectedBlock,
        setSelectedBlock,
        messageCount,
        conversationTokenUsage,
        suggestions
    }: MessageFormProps) => {
        const [isInvoking, setIsInvoking] = useState(false)
        const { permissions } = useUserPermissions()
        const formRef = useRef(null)

        const handleSubmit = (e: React.FormEvent) => {
            e.preventDefault()
            if (!message.trim() || isInvoking) return

            setIsInvoking(true)
            try {
                sendMessage(message.trim())
                setMessage('')
            } finally {
                setIsInvoking(false)
            }
        }
        const selectedBlockType = capitalizeWords(
            (selectedBlock as FilterTypes)?.table?.replace('patient_', '').replace('_', ' ') ?? 'Patient Attributes'
        )

        return (
            <form ref={formRef} onSubmit={handleSubmit}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '.25rem' }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        {permissions.includes(PlatformPermissions.ACCESS_ADVANCED_ASSISTANT) && (
                            <>
                                <select
                                    value={model}
                                    onChange={(e) => setModel(e.target.value as ChatModel)}
                                    style={{
                                        padding: '0.5rem',
                                        backgroundColor: 'white',
                                        color: '#2d3748',
                                        transition: 'border-color 0.2s',
                                        outline: 'none',
                                        border: 'none',
                                        textAlign: 'left',
                                        margin: '0.5rem 0.5rem 0.5rem 1.5rem'
                                    }}
                                >
                                    {Object.values(ChatModels).map((model) => (
                                        <option key={model} value={model}>
                                            {model}
                                        </option>
                                    ))}
                                </select>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        padding: '0.5rem',
                                        margin: '0.5rem',
                                        backgroundColor: '#f0f4f8',
                                        border: '1px solid #cbd5e0',
                                        borderRadius: '0.25rem',
                                        fontSize: '0.875rem',
                                        color: '#4a5568'
                                    }}
                                >
                                    <Tooltip title='Number of tokens used in this conversation'>
                                        <div style={{ display: 'flex', gap: '8px' }}>
                                            <span style={{ fontWeight: 'bold' }}>
                                                <Trans>Token Usage</Trans>
                                            </span>
                                            <span>{conversationTokenUsage.totalTokens.toLocaleString()}</span>
                                            <span style={{ fontSize: '0.75rem', color: '#718096' }}>
                                                (In: {conversationTokenUsage.inputTokens.toLocaleString()}, Out:{' '}
                                                {conversationTokenUsage.outputTokens.toLocaleString()})
                                            </span>
                                        </div>
                                    </Tooltip>
                                </div>
                            </>
                        )}
                        {selectedBlock?.id !== undefined && (
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '0.5rem',
                                    marginLeft: '2rem',
                                    padding: '0.25rem',
                                    margin: '0.5rem 0.5rem 0.5rem 1.5rem',
                                    boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
                                    border: '1px dashed blue'
                                }}
                            >
                                <Tooltip title={`ID: ${selectedBlock.id}`}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        {selectedBlockType && (
                                            <>
                                                <i style={{ color: '#1e40af' }}>
                                                    <CriteriaTypeIcon criteriaType={selectedBlockType} />
                                                </i>
                                                <span style={{ fontWeight: '800', color: '#103275', marginLeft: '0.5rem', cursor: 'default' }}>
                                                    {selectedBlockType}
                                                </span>
                                            </>
                                        )}

                                        <span style={{ marginLeft: '8px' }}>
                                            {selectedBlock.name ? `${capitalizeFirstLetter(selectedBlock.name)}` : ``}
                                        </span>
                                    </div>
                                </Tooltip>
                                <button
                                    onClick={() => setSelectedBlock?.(undefined)}
                                    style={{
                                        background: 'none',
                                        border: 'none',
                                        color: 'red',
                                        cursor: 'pointer',
                                        fontSize: '1rem'
                                    }}
                                >
                                    &times;
                                </button>
                            </div>
                        )}
                        {messageCount === 0 && suggestions.length > 0 && (
                            <div style={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}>
                                {suggestions.map((suggestion, index) => (
                                    <button
                                        key={index}
                                        style={{
                                            backgroundColor: '#FFFFFF', // A nice blue shade
                                            color: '#012D72',
                                            padding: '5px 20px', // Reduced vertical padding for a shorter button
                                            border: '1px solid #012D72',
                                            borderRadius: '25px', // Rounded corners
                                            cursor: 'pointer',
                                            marginBottom: '10px',
                                            marginTop: '10px',
                                            fontSize: '14px',
                                            boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
                                            transition: 'background-color 0.3s ease'
                                        }}
                                        onClick={() => setMessage(suggestion)}
                                    >
                                        &quot;{suggestion}&quot;
                                    </button>
                                ))}
                            </div>
                        )}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '1rem' }}>
                        <MessageInput message={message} setMessage={setMessage} isInvoking={isInvoking} formRef={formRef} />
                        <button
                            type='submit'
                            disabled={!message.trim() || isInvoking}
                            style={{
                                padding: '0.5rem 1rem',
                                color: '#ffffff',
                                borderRadius: '0.5rem',
                                transition: 'background-color 0.2s',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '0.5rem',
                                backgroundColor: isInvoking ? '#63b3ed' : '#3182ce'
                            }}
                        >
                            <Send style={{ width: '1rem', height: '1rem' }} />
                            <span>Send</span>
                        </button>
                    </div>
                </div>
            </form>
        )
    }
)

MessageForm.displayName = 'MessageForm'
