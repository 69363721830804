/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { Message } from '@chatscope/chat-ui-kit-react'
import React from 'react'
import { NodeTypes } from '../components/query/QueryBlock'
import { FormattedMessageContent } from './FormattedMessageContent'
import { selectedFilterKey } from './SystemMessages'
import { ToolCalls } from './ToolCalls'

interface RenderedMessageProps {
    message: any
    toolCallsAreComplete: Map<string, { complete: boolean; status?: string; error?: string }>
    selectedBlock: NodeTypes | undefined
}

export const RenderedMessage: React.FC<RenderedMessageProps> = ({ message, toolCallsAreComplete, selectedBlock }) => {
    const type =
        message['type'] === 'ai'
            ? message['additional_kwargs']?.tool_calls && message['additional_kwargs'].tool_calls.length > 0
                ? 'tool'
                : 'ai'
            : message['type']

    switch (type) {
        case 'tool': {
            if (message.tool_call_id) {
                return null
            }
            const toolCallId = message.additional_kwargs.tool_calls[0].id
            const toolCallStatus = toolCallsAreComplete.get(toolCallId)
            return message.additional_kwargs.tool_calls.map((toolCall) => (
                <ToolCalls
                    key={`tool-call-${message.id}`}
                    toolCall={toolCall}
                    isComplete={toolCallStatus?.complete || false}
                    status={toolCallStatus?.status}
                    error={toolCallStatus?.error}
                    inputTokens={message.usage_metadata?.input_tokens}
                    outputTokens={message.usage_metadata?.output_tokens}
                    totalTokens={message.usage_metadata?.total_tokens}
                />
            ))
        }
        case 'human':
            return (
                <Message
                    key={`human-message`}
                    model={{
                        sentTime: 'just now',
                        direction: 'outgoing',
                        position: 'normal',
                        type: 'html'
                    }}
                    style={{
                        color: '#012D72',
                        padding: '2px 4px',
                        margin: '1px 0',
                        lineHeight: '1.2'
                    }}
                >
                    <Message.CustomContent>
                        <FormattedMessageContent message={message} />
                    </Message.CustomContent>
                </Message>
            )
        case 'ai':
            return (
                <Message
                    key={`ai-message`}
                    model={{
                        sentTime: 'just now',
                        direction: 'incoming',
                        position: 'normal',
                        type: 'html'
                    }}
                    style={{
                        color: '#000000',
                        padding: '2px 4px',
                        margin: '1px 0',
                        lineHeight: '1.2'
                    }}
                >
                    <Message.CustomContent>
                        <FormattedMessageContent message={message} />
                    </Message.CustomContent>
                </Message>
            )
        case 'system':
            return (
                <Message
                    key={`system-message`}
                    model={{
                        sentTime: 'just now',
                        direction: 'outgoing',
                        position: 'normal',
                        type: 'html'
                    }}
                    style={{
                        color: '#000000',
                        marginTop: '-.75rem',
                        fontWeight: 'lighter',
                        fontFamily: ['Metropolis', 'sans-serif'].join(',')
                    }}
                >
                    <Message.CustomContent>
                        <FormattedMessageContent
                            message={{
                                content: message.content.includes(selectedFilterKey) ? `Selected filter` : message.content
                            }}
                        />
                    </Message.CustomContent>
                </Message>
            )
        default:
            return <>{JSON.stringify(message)}</>
    }
}
