import { GetNextPageParamFunction, useInfiniteQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { useMemo } from 'react'
import { IngestConversationsService, PaginatedDTO_IngestConversationDTO_ } from '../client'

export const QUERY_KEYS = {
    ingestConversations: 'ingestConversations'
} as const

export function useIngestConversations(params: Parameters<typeof IngestConversationsService.indexIngestConversationsGet>[0]) {
    const memoizedParams = useMemo(() => params, [params])

    const getNextPageParam: GetNextPageParamFunction<PaginatedDTO_IngestConversationDTO_> = (lastPage) => {
        return lastPage.meta.currentPage < lastPage.meta.totalPages ? lastPage.meta.currentPage + 1 : undefined
    }

    const {
        data: ingestConversations,
        error,
        isLoading,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage
    } = useInfiniteQuery<PaginatedDTO_IngestConversationDTO_>({
        queryKey: [QUERY_KEYS.ingestConversations, memoizedParams.page, memoizedParams.limit],
        queryFn: () =>
            IngestConversationsService.indexIngestConversationsGet({
                page: memoizedParams.page,
                limit: memoizedParams.limit
            }),
        getNextPageParam
    })

    return {
        ingestConversations,
        error,
        isLoading,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage
    }
}

export function useCreateConversation() {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (params: Parameters<typeof IngestConversationsService.createIngestConversationsPost>[0]) =>
            IngestConversationsService.createIngestConversationsPost(params),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ingestConversations] })
        }
    })
}

export function useUpdateConversation() {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (params: Parameters<typeof IngestConversationsService.patchIngestConversationsIdPatch>[0]) =>
            IngestConversationsService.patchIngestConversationsIdPatch(params),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ingestConversations] })
        }
    })
}

export function useDeleteConversation() {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (params: Parameters<typeof IngestConversationsService.deleteIngestConversationsIdDelete>[0]) =>
            IngestConversationsService.deleteIngestConversationsIdDelete(params),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ingestConversations] })
        }
    })
}
