import { Trans, t } from '@lingui/macro'
import { Button, CircularProgress, Box, Typography, Paper } from '@mui/material'
import React, { useCallback, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { AnalyticsAdminTasksService, TaskChainDTO, falconApiConfig } from '@om1/falcon-api'
import { OpenAPI } from '@om1/falcon-api'
import { notificationActions } from '@om1/platform-notifications'
import RefreshIcon from '@mui/icons-material/Refresh'

export const AnalyticsBuildPageComponent: React.FC = () => {
    const dispatch = useDispatch()
    const [isRefreshing, setIsRefreshing] = React.useState(false)
    const [taskId, setTaskId] = React.useState<string | null>(null)
    const [completionTime, setCompletionTime] = React.useState<string | null>(null)
    const wsRef = useRef<WebSocket | null>(null)

    const handleRefresh = useCallback(async () => {
        try {
            setIsRefreshing(true)
            setTaskId(null)
            setCompletionTime(null)
            const taskChain: TaskChainDTO =
                await AnalyticsAdminTasksService.refreshProductCustomCohortAnalyticsAdminTasksRefreshProductCustomCohortGet()

            setTaskId(taskChain.taskId)
            const websocketUrl = `${falconApiConfig.falconApiUrl?.replace(new RegExp(/^http/), 'ws')}/tasks/ws/${taskChain.taskId}`
            const ws = new WebSocket(websocketUrl)
            wsRef.current = ws

            ws.onopen = () => {
                ws.send(JSON.stringify({ token: String(OpenAPI.TOKEN) }))
            }

            ws.onmessage = (event) => {
                const message = JSON.parse(event.data)
                if (message.status?.toLowerCase() === 'success') {
                    dispatch(notificationActions.success(t`Product custom cohort refresh completed successfully`))
                    setIsRefreshing(false)
                    setCompletionTime(new Date().toLocaleString())
                } else if (message.status?.toLowerCase() === 'failure') {
                    dispatch(notificationActions.error(message.result?.error || t`Failed to refresh product custom cohort`))
                    setIsRefreshing(false)
                }
            }

            ws.onclose = () => {
                setIsRefreshing(false)
            }

            ws.onerror = (error) => {
                dispatch(notificationActions.error(t`WebSocket connection error`))
                setIsRefreshing(false)
            }
        } catch (error) {
            dispatch(notificationActions.error(t`Failed to start product custom cohort refresh`))
            setIsRefreshing(false)
        }
    }, [dispatch])

    useEffect(() => {
        return () => {
            if (wsRef.current) {
                wsRef.current.close()
            }
        }
    }, [])

    return (
        <Box sx={{ p: 3 }}>
            <Paper sx={{ p: 3, mb: 3 }}>
                <Typography variant='h5' component='h1' gutterBottom>
                    <Trans>Refresh Product Custom Cohorts</Trans>
                </Typography>
                <Typography variant='body1' color='text.secondary' sx={{ mb: 3 }}>
                    <Trans>
                        Note: If you refresh the page or navigate away, you will lose tracking of the running task. The refresh process may take up to
                        20 minutes to complete.
                    </Trans>
                </Typography>
                <Button
                    variant='contained'
                    color='primary'
                    startIcon={isRefreshing ? <CircularProgress size={20} color='inherit' /> : <RefreshIcon />}
                    onClick={handleRefresh}
                    disabled={isRefreshing}
                    sx={{
                        minWidth: 120,
                        position: 'relative'
                    }}
                >
                    {isRefreshing ? <Trans>Refreshing...</Trans> : <Trans>Refresh</Trans>}
                </Button>
                {taskId && (
                    <Typography variant='body2' sx={{ mt: 2, color: 'text.secondary' }}>
                        <Trans>Task ID:</Trans> {taskId}
                    </Typography>
                )}
                {completionTime && (
                    <Typography variant='body2' sx={{ mt: 1, color: 'success.main' }}>
                        <Trans>Completed at:</Trans> {completionTime}
                    </Typography>
                )}
            </Paper>
        </Box>
    )
}
